import { api } from '../../../services'

export interface Tutor {
  id: number
  endereco: {
    id: number
    municipio: string
    logradouro: string
    numero: string
    bairro: string
    complemento: string
    ponto_de_referencia: string
    uf: string
    cep: string
  }
  nome: string
  data_nascimento: string
  cpf: string
  rg: string
  data_expedicao: string
  orgao_expedidor: string
  uf_orgao_expedidor: string
  email: string
  telefone: string
  telefone_alternativo: string
}

export const getTutor = async (id: number): Promise<Tutor> => {
  const url = `/tutores/${id}`
  const { data } = await api.get<Tutor>(url)
  return data
}
