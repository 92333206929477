import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ChakraProvider } from '@chakra-ui/react'

import { Toast } from './components'
import { Routes } from './routes'
import { queryClient } from './services'

import { theme } from './styles/theme'
import { AuthProvider } from './data/context/AuthContext'
import { SidebarDrawerProvider } from './data/context/SidebarDrawerContext'
import { RequestFiltersProvider } from './pages/RequestsList/context/RequestFiltersContext'

const isDevelopment = process.env.NODE_ENV === 'development'

export function App() {
  return (
    <Router>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <Toast />
            <SidebarDrawerProvider>
              <RequestFiltersProvider>
                <Routes />
              </RequestFiltersProvider>
            </SidebarDrawerProvider>
          </ChakraProvider>
          {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </AuthProvider>
    </Router>
  )
}
