import { yupResolver } from '@hookform/resolvers/yup'
import { useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Error,
  RedefinePasswordFormData,
  RedefinePasswordSchema,
  RequestCodeFormData,
  RequestCodeSchema,
  services
} from '../../logic/core'
import { Errors } from '../../logic/utils/Errors'

export function useRedefinePassword() {
  const formRef = useRef<any>(null)
  const showFormCode = useRef(true)
  const showFormPassword = useRef(false)
  const navigate = useNavigate()
  const { passwords } = services

  const {
    register: registerRequestCode,
    handleSubmit: handleSubmitRequestCode,
    formState: formStateRequestCode,
    watch: watchRequestCode,
    setError: setErrorRequestCode,
  } = useForm<RequestCodeFormData>({
    resolver: yupResolver(RequestCodeSchema),
  })

  const handleRequestCode: SubmitHandler<RequestCodeFormData> = async (
    values,
    event,
  ) => {
    event?.preventDefault()
    await new Promise((resolve) => setTimeout(resolve, 2000))

    if (formRef.current) {
      try {
        const { status, data } = await passwords.requestCode(values)
        if (status === 200) {
          showFormCode.current = false
          showFormPassword.current = true
        }
        toast.success(data.mensagem)
      } catch (errorRequestCode: any) {
        if (errorRequestCode.response.status === 400) {
          const data = errorRequestCode.response.data

          const mapErrors = Errors.mapErrors(data)
          mapErrors.forEach(({ type, name, message }: Error) => {
            setErrorRequestCode(name, { type, message })
          })

          toast.error(
            'Ocorreu um erro ao tentar redefinir sua senha, verifique os campos em destaque!',
          )
        }

        const data = errorRequestCode?.response.data

        if (data.detail) {
          toast.error(`${data.detail}`)
        }

        if (data.mensagem) {
          toast.error(`${data.mensagem}`)
        }
      }
    }
  }

  const {
    register: registerRedefinePassword,
    handleSubmit: handleSubmitRedefinePassword,
    formState: formStateRedefinePassword,
    setValue: setValueRedefinePassword,
    setError: setErrorRedefinePassword,
  } = useForm<RedefinePasswordFormData>({
    resolver: yupResolver(RedefinePasswordSchema),
  })

  setValueRedefinePassword('cpf', watchRequestCode('cpf'))

  const handleRedefinePassword: SubmitHandler<
    RedefinePasswordFormData
  > = async (values, event) => {
    event?.preventDefault()
    await new Promise((resolve) => setTimeout(resolve, 2000))

    if (formRef.current) {
      try {
        delete values.confirmar_nova_senha

        const { status, data } = await passwords.redefinePassword(values)
        if (status === 200) {
          showFormPassword.current = false
          toast.success(data.mensagem)
          navigate('/')
        }
      } catch (errorRedefinePassword: any) {
        if (errorRedefinePassword.response.status === 400) {
          const data = errorRedefinePassword.response.data

          const mapErrors = Errors.mapErrors(data)
          mapErrors.forEach(({ type, name, message }: Error) => {
            setErrorRedefinePassword(name, { type, message })
          })

          toast.error(
            'Ocorreu um erro ao tentar redefinir sua senha, verifique os campos em destaque!',
          )
        }

        const data = errorRedefinePassword?.response.data

        if (data.detail) {
          toast.error(`${data.detail}`)
        }

        if (data.mensagem) {
          toast.error(`${data.mensagem}`)
        }
      }
    }
  }

  return {
    formStateRequestCode,
    handleRequestCode,
    handleSubmitRequestCode,
    registerRequestCode,
    watchRequestCode,
    formStateRedefinePassword,
    handleRedefinePassword,
    handleSubmitRedefinePassword,
    registerRedefinePassword,
    formRef,
    showFormCode,
    showFormPassword,
  }
}
