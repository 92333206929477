import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { RiCloseLine } from 'react-icons/ri'
import {
  FormControlItem,
  Input,
  Modal,
  ModalConfirm,
  SecondaryButton,
  SectionDivider,
  SectionForm,
  SectionSubtitle,
  SectionTitle,
  Select,
  TertiaryButton,
} from '../../../../components'
import { useInstitutions } from '../../hook/useInstitutions'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTutor } from '../../hook/useTutor'
import { queryClient } from '../../../../services/react-query/query-client'
import { CpfFormatting, TelefoneFormatting } from '../../../../utils'
import { updateGuardian } from '../../hook/useUpdateGuardian'
import { useAuth } from '../../../../data/context/AuthContext'

export function ModalUpdateGuardian({ isOpen, onClose, orfaoId }: any) {
  const { user } = useAuth()
  const modalSize = useBreakpointValue({ base: 'xs', md: '4xl', '3xl': '5xl' })

  const [cpf, setCPF] = useState('')
  const [errors, setErrors] = useState<string | null>(null)
  const [tutorId, setTutorId] = useState<number | null>(null)
  const [instituicaoId, setInstituicaoId] = useState<number | null>(null)
  const [tipoDeResponsavelLegal, setTipoDeResponsavelLegal] = useState('tutor')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  const onCloseModal = () => {
    setTipoDeResponsavelLegal('tutor')
    setCPF('')
    setInstituicaoId(null)
    setErrors(null)
    onClose()
  }

  

  const institutions = useInstitutions()

  const { data: tutor } = useTutor({
    tutorCPF: Number(CpfFormatting.unFormat(cpf)),
  })

  useEffect(() => {
    if (isValidCPF(cpf)) {
      queryClient.invalidateQueries('simpleTutor')
    }
    if (tutor && isValidCPF(cpf)) {
      setTutorId(tutor.id)
      setInstituicaoId(null)
      setErrors(null)
    } else if (isValidCPF(cpf)) {
      setErrors('CPF não encontrado')
    }
  }, [cpf, tutor])

  const handleConfirm = () => {
    setIsSubmitting(true)

    const response = updateGuardian({
      agentecrasId: user?.agentecras_id,
      orfaoId,
      tutorId,
      instituicaoId,
    })
    setIsSubmitting(false)
    onCloseConfirm()
    onCloseModal()
  }

  //Verifica se o CPF é válido
  function isValidCPF(cpf: string): boolean {
    const regex = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/
    return regex.test(cpf)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCPF(value)

    if (isValidCPF(value)) {
      setErrors(null)
    } else {
      setErrors('O CPF deve conter 9 digitos')
    }
  }

  const handleInstituicaoChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedId = parseInt(event.target.value, 10) // Converte o valor para um número inteiro
    setInstituicaoId(selectedId) // Atualiza o estado com o valor selecionado
    setTutorId(null)
  }

  return (
    <>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isSubmitting={isSubmitting}
        title={'Confirmar atualização'}
        description={
          'Após a confirmação, a mudança do responsável legal será definitiva. Deseja realmente confirmar essa alteração?'
        }
        onConfirm={handleConfirm}
      />

      <Modal.Root
        size={modalSize}
        isOpen={isOpen}
        onClose={onCloseModal}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent overflowY={'auto'}>
          <Box>
            <Modal.Header>
              <Flex
                justify="space-between"
                alignItems="center"
                width="100%"
                gap="10px"
              >
                <Flex
                  justify="center"
                  align="flex-start"
                  textTransform="uppercase"
                >
                  ATUALIZAR RESPONSÁVEL LEGAL
                </Flex>
                <IconButton
                  aria-label="Fechar modal"
                  color="blackAlpha.900"
                  icon={<Icon as={RiCloseLine} fontSize="1.5rem" />}
                  mt="0.20rem"
                  size="xs"
                  variant="unstated"
                  onClick={onCloseModal}
                />
              </Flex>
              <SectionDivider />
            </Modal.Header>

            <Modal.Body>
              <Flex mb={'1rem'}>
                <FormControlItem label="Tipo de responsável legal">
                  <RadioGroup
                    onChange={setTipoDeResponsavelLegal}
                    value={tipoDeResponsavelLegal}
                  >
                    <Flex h={'2.5rem'} gap={'1rem'}>
                      <Radio value="tutor">Tutor</Radio>
                      <Radio value="instituicao">
                        Instituição de acolhimento
                      </Radio>
                    </Flex>
                  </RadioGroup>
                </FormControlItem>
              </Flex>

              {tipoDeResponsavelLegal === 'instituicao' ? (
                <>
                  <Flex mb={'2rem'} mt={'2rem'}>
                    <Select
                      label="instituicao"
                      value={instituicaoId || ''}
                      onChange={handleInstituicaoChange}
                    >
                      <option value="" disabled hidden>
                        Selecione uma instituição
                      </option>
                      {institutions?.map((institution) => (
                        <option key={institution.id} value={institution.id}>
                          {institution.nome}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                </>
              ) : tipoDeResponsavelLegal === 'tutor' ? (
                <>
                  <Flex direction="column" alignItems="center" mt={4}>
                    <Input
                      label="CPF do Tutor"
                      type="text"
                      mask="999.999.999-99"
                      value={cpf}
                      onChange={handleChange}
                      isInvalid={!!errors}
                    />
                    {errors && (
                      <Text color={'red.500'} mt={'0.5rem'}>
                        {errors}
                      </Text>
                    )}
                  </Flex>

                  <SectionForm>
                    <SectionTitle>Dados de pessoais</SectionTitle>
                    <SectionSubtitle>
                      Dados pessoais do tutor informado
                    </SectionSubtitle>
                    <SectionDivider />
                  </SectionForm>

                  <Grid
                    templateAreas={{
                      base: `"nome_completo" "cpf" "email" "telefone"`,
                      md: `"nome_completo cpf" "email telefone"`,
                    }}
                    gridTemplateColumns={{
                      base: 'repeat(1, 1fr)',
                      md: 'repeat(2, 1fr)',
                    }}
                    mb={'2rem'}
                    mt={'2rem'}
                    gap={'2rem'}
                    w={'100%'}
                  >
                    <GridItem area={'nome_completo'}>
                      <Input
                        label="Nome completo"
                        isReadOnly
                        value={tutor?.nome ?? ''}
                      />
                    </GridItem>
                    <GridItem area={'cpf'}>
                      <Input
                        label="CPF"
                        isReadOnly
                        value={CpfFormatting.format(tutor?.cpf ?? '')}
                      />
                    </GridItem>
                    <GridItem area={'email'}>
                      <Input
                        label="Email"
                        isReadOnly
                        value={tutor?.email ?? ''}
                      />
                    </GridItem>

                    <GridItem area={'telefone'}>
                      <Input
                        label="telefone"
                        isReadOnly
                        value={TelefoneFormatting.format(tutor?.telefone)}
                      />
                    </GridItem>
                  </Grid>
                </>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <TertiaryButton onClick={onCloseModal}>Fechar</TertiaryButton>
              <SecondaryButton
                onClick={onOpenConfirm}
                isLoading={isSubmitting}
                loadingText="Confirmando"
                disabled={!!tutorId && !!instituicaoId}
              >
                Confirmar
              </SecondaryButton>
            </Modal.Footer>
          </Box>
        </ModalContent>
      </Modal.Root>
    </>
  )
}
