import { toast } from 'react-toastify'
import { api } from '../../../services'

export interface Anexo {
  id: number
  arquivo: string
  valido: boolean
  tipo: string
  justificativa: string
}

export interface Purport {
  id: number
  created_at: string
  nome_orfao: string
  status: number
  municipio: string
  parecer: string
  anexos: Anexo[]
}

export const getPurport = async (
  agenteId: number,
  requestId: number,
): Promise<Purport | undefined> => {
  try {
    const { data } = await api.get<Purport>(
      `/solicitacoes/${requestId}`,
    )
    return data
  } catch (error) {
    toast.error('Ocorreu um erro ao visualizar o parecer!')
    return undefined
  }
}
