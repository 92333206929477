import { useAttachFile } from '../../pages/OrfaoDetail/hook/useAttachFile';
import { createContext, useContext, useState } from 'react';

interface AttachmentsContextProps {
  children: React.ReactNode
}

type AttachmentsContextData = ReturnType<typeof useAttachFile>

export const AttachmentsContext = createContext({} as AttachmentsContextData)

export function AttachmentsProvider({ children }: AttachmentsContextProps) {
  const value = useAttachFile()

  return (
    <AttachmentsContext.Provider value={value}>
      {children}
    </AttachmentsContext.Provider>
  )
}