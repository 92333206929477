import { useState } from 'react'
import { api, queryClient } from '../../../services'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AttachmentFormValidatorSchema } from '../schemas/AttachmentFormValidatorSchema'
import { toast } from 'react-toastify'

export interface AttachmentFormData {
  documento: FileList | null
}

export const useAttachFile = () => {
  const {
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AttachmentFormData>({
    resolver: yupResolver(AttachmentFormValidatorSchema),
  })
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [nameFile, setNameFile] = useState<string>('')
  const [documento, setDocumento] = useState<File | null>(null)
  const [loadingSubmitting, setLoadingSubmitting] = useState(false)

  async function progressAttachment() {
    setLoading(true)
    setProgress(0)
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1)
    }, 20)
    await new Promise((resolve) => setTimeout(resolve, 2000)).finally(() => {
      clearInterval(interval)
    })
    setLoading(false)
  }

  const [formData, setFormData] = useState<FormData>(new FormData())

  async function handleFileAttachmentReportDeath(
    orphanId: number,
    onclose: () => void,
  ) {
    setLoadingSubmitting(true)
    const formData = new FormData()

    if (documento) formData.append('documento', documento)

    const valid = await trigger('documento')
    if (!errors['documento'] && valid) {
      try {
        const url = `agentescras/informar-obito/orfao/${orphanId}/`
        await api.post(url, formData)
        onclose()
        toast.success(
          'O óbito foi informado com sucesso, em breve será analisado',
        )
        queryClient.invalidateQueries('orfao')
      } catch (error) {
        toast.error('Erro ao enviar o arquivo, tente novamente mais tarde')
      } finally {
        setLoadingSubmitting(false)
      }
    }
  }

  async function handleFileAttachmentAdoption(
    orphanId: number,
    onclose: () => void,
  ) {
    setLoadingSubmitting(true)
    const formData = new FormData()

    if (documento) formData.append('documento', documento)

    const valid = await trigger('documento')
    if (!errors['documento'] && valid) {
      try {
        const url = `agentescras/informar-adocao/orfao/${orphanId}/`
        await api.post(url, formData)
        onclose()
        toast.success(
          'A adoção foi informada com sucesso, em breve será analisada',
        )
        queryClient.invalidateQueries('orfao')
      } catch (error) {
        toast.error('Erro ao enviar o arquivo, tente novamente mais tarde')
      } finally {
        setLoadingSubmitting(false)
      }
    }
  }

  function handleFileAttachmentAbort() {
    setNameFile('')
    setProgress(100)
  }

  return {
    handleFileAttachmentReportDeath,
    handleFileAttachmentAdoption,
    handleFileAttachmentAbort,
    progressAttachment,
    setNameFile,
    setValue,
    getValues,
    trigger,
    errors,
    loading,
    progress,
    nameFile,
    formData,
    setFormData,
    documento,
    setDocumento,
    loadingSubmitting,
  }
}
