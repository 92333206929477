import { ReactNode } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'

interface ContentProps {
  children: ReactNode
}

export function Content({ children }: ContentProps) {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <Box
      as="main"
      flex="1"
      borderRadius={isWideVersion ? '8' : '0'}
      bg="platinum.100"
      p="8"
    >
      {children}
    </Box>
  )
}
