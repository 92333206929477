import jwt_decode from 'jwt-decode'
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react'
import Cookies from 'js-cookie'

import { queryClient } from '../../services/react-query/query-client'
import {
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken,
} from '../../store'
import { api } from '../../services/axios/axios'

type User = {
  agentecras_id: number
  nome: string
  cpf: string
}

type AuthContextData = {
  signIn: (access: string, refresh: string) => void
  signOut: () => void
  user?: User
  isAuthenticated: boolean
}

type Token = {
  token_type: string
  exp: number
  iat: number
  jti: string
  user_id: number
  agentecras_id: number
  nome: string
  cpf: string
}

export function jwtDecode(token: string): Readonly<Token> {
  return jwt_decode(token)
}

const AuthContext = createContext({} as AuthContextData)

type AuthProviderProps = {
  children: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const access = getToken()
    const refresh = getRefreshToken()

    async function refreshTokenApi() {
      try {
        const url = `/refresh/`
        const response = await api.post(url, { refresh: refresh })

        const { access: accessToken } = response.data
        if (accessToken && refresh) {
          signIn(accessToken, refresh)
        }
      } catch (error) {
        signOut()
        return
      }
    }

    if (access && refresh) {
      const { exp } = jwtDecode(access)
      const now = new Date().getTime() / 1000

      // Chame refreshToken se o token de acesso estiver prestes a expirar
      if (exp < now + 60 * 5) {
        // Chame refreshToken se faltar menos de 5 minutos para a expiração
        refreshTokenApi()
      }

      const { agentecras_id, nome, cpf } = jwtDecode(access)
      setUser({ agentecras_id, nome, cpf })
      setIsAuthenticated(true)
    } else {
      signOut()
    }
  }, [])

  async function signIn(access: string, refresh: string) {
    if (access && refresh) {
      const { agentecras_id, nome, cpf } = jwtDecode(access)

      if (agentecras_id) {
        setToken(access)
        setRefreshToken(refresh)

        setUser({ agentecras_id, nome, cpf })
        setIsAuthenticated(true)
      }
    }
  }

  function signOut() {
    Cookies.remove('agenteCRASAccessToken')
    Cookies.remove('agenteCRASRefreshToken')

    queryClient.removeQueries()

    setUser(undefined)
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider value={{ signIn, signOut, user, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth deve ser usado em um AuthProvider')
  }

  return context
}
