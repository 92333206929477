import { AxiosResponse } from 'axios'
import { api } from '../../../services/axios/axios'
import { RedefinePasswordFormData, RequestCodeFormData } from './'

export class ServicesPassword {
  async requestCode(
    data: RequestCodeFormData,
  ): Promise<AxiosResponse<any, any>> {
    const url = 'senhas/redefinir/'
    const response = await api.post(url, data)
    return response
  }

  async redefinePassword(
    data: RedefinePasswordFormData,
  ): Promise<AxiosResponse<any, any>> {
    const url = 'senhas/atualizar/'
    const response = await api.post(url, data)
    return response
  }
}
