import { useQuery } from 'react-query'

import { getBenefitRequests } from '../data-fetcher/get-requests'

export interface BenefitsRequests {
  currentPage: number
  perPage: number
  agenteId?: number
  filtros: {
    status?: string
    municipio?: string
    cpf_tutor?: string
    instituicao?: string
    cpf_orfao?: string
  }
}

export const useBenefitRequests = ({
  currentPage,
  perPage,
  agenteId,
  filtros,
}: BenefitsRequests) => {
  return useQuery(
    ['requests', currentPage, perPage, agenteId, filtros],
    () => getBenefitRequests({ currentPage, perPage, agenteId, filtros }),
    {
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!agenteId,
    },
  )
}
