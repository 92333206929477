import { Stack } from '@chakra-ui/react'
import { RiFileListLine, RiGroupLine, RiOpenArmLine } from 'react-icons/ri'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Stack spacing={'12'} align={'flex-start'}>
      <NavSection title={'GERAL'}>
        <NavLink href="/requests" icon={RiFileListLine}>
          Solicitações de benefícios
        </NavLink>
        <NavLink href="/tutores" icon={RiGroupLine}>
          Tutores
        </NavLink>
        <NavLink href="/orfaos" icon={RiOpenArmLine}>
          Orfãos
        </NavLink>
        <NavLink href="/maintenance-requests" icon={RiGroupLine}>
          Solicitações de manutenção do benefício
        </NavLink>
      </NavSection>
    </Stack>
  )
}
