import { useState } from 'react'

export function useFilters() {
  const [statusId, setStatusId] = useState('')
  const [cityId, setCityId] = useState('')
  const [guardiao, setGuardiao] = useState('')
  const [instituicaoId, setInstituicaoId] = useState('')
  const [CPFTutor, setCPFTutor] = useState('')
  const [CPFOrfao, setCPFOrfao] = useState('')

  function observerChangeInSelectStatus(value: string) {
    setStatusId(value.split(' ').join(''))
  }

  function observerChangeInSelectCity(value: string) {
    setCityId(value.split(' ').join(''))
  }

  function observerChangeInSelectGuardioes(value: string) {
    if (guardiao !== value) {
      setCPFTutor('')
      setInstituicaoId('')
      setGuardiao(value.split(' ').join(''))
    }
  }

  function observerChangeInSelectInstituicoes(value: string) {
    if (instituicaoId !== value) {
      setInstituicaoId(value.split(' ').join(''))
    }
  }

  function observerChangeInInputCPFTutor(value: string) {
    const cpf = removeFormattingForCPF(value)
    if (cpf.length === 11) {
      return setCPFTutor(cpf)
    }
    setCPFTutor('')
  }

  function removeFormattingForCPF(value: string) {
    return value.replace(/\D/g, '')
  }
  function observerChangeInInputCPFOrfao(value: string) {
    const cpf = removeFormattingForCPF(value)
    if (cpf.length === 11) {
      return setCPFOrfao(cpf)
    }
    setCPFOrfao('')
  }

  function handleButtonDelete() {
    setStatusId('')
    setCityId('')
    setGuardiao('')
    setCPFTutor('')
    setCPFOrfao('')
    setInstituicaoId('')
  }

  return {
    statusId,
    cityId,
    guardiao,
    instituicaoId,
    CPFTutor,
    CPFOrfao,
    observerChangeInSelectStatus,
    observerChangeInSelectCity,
    observerChangeInSelectGuardioes,
    observerChangeInSelectInstituicoes,
    observerChangeInInputCPFTutor,
    observerChangeInInputCPFOrfao,
    handleButtonDelete,
  }
}
