import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Button,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { FormControlItem } from '../../../components'
import { FormInput } from './interfaces'

type InputProps = Omit<FormInput, 'mask' | 'format' | 'isAllowed'> &
  ChakraInputProps

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    _required = true,
    disabled = false,
    error,
    info,
    label,
    name,
    ...rest
  },
  ref,
) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  return (
    <FormControlItem
      _required={_required}
      disabled={disabled}
      label={label}
      info={info}
      error={error}
      name={name}
    >
      <InputGroup>
        <ChakraInput
          type={show ? 'text' : 'password'}
          id={name}
          name={name}
          focusBorderColor={'blue.300'}
          borderColor={'black.300'}
          variant={'flushed'}
          _hover={{ borderColor: 'blue.300' }}
          size={'md'}
          ref={ref}
          {...rest}
        />
        <InputRightElement>
          <Button
            size="sm"
            onClick={handleClick}
            variant="unstyled"
            title={show ? 'Ocultar senha' : 'Mostrar senha'}
          >
            {!show && <ViewIcon color="black.900" />}
            {show && <ViewOffIcon color="black.900" />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControlItem>
  )
}

export const Password = forwardRef(InputBase)
