import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { GuardianFormEnderecoData } from './GuardianFormData'
import { GuardianFormValidatorEnderecoSchema } from '../schemas/GuardianFormValidatorEnderecoSchema'

import { updateTutor } from '../data-fetcher/update-tutor'
import { Tutor } from '../data-fetcher/get-tutor'

export function useUpdateEnderecoTutor(tutor: Tutor) {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<GuardianFormEnderecoData>({
    resolver: yupResolver(GuardianFormValidatorEnderecoSchema),
    mode: 'onChange'
  })

  const updateEndereco: SubmitHandler<GuardianFormEnderecoData> = async (
    values: any,
  ) => {
    const dataEndereco = {
      endereco: {
        ...values,
      },
    }
    const { data } = await updateTutor(tutor.id as number, dataEndereco)
    toast.success('Tutor atualizado com sucesso!')
    return data
  }

  return {
    register,
    handleSubmit,
    setError,
    updateEndereco,
    watch,
    errors,
    isSubmitting,
  }
}
