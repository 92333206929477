import { api } from '../../../services'

export interface SimpleTutor {
  id: number
  nome: string
  cpf: string
  email: string
  telefone: string
}

export const getTutor = async (cpf: number): Promise<SimpleTutor> => {
  const url = `/tutores/consultar/${cpf}`
  const { data } = await api.get<SimpleTutor>(url)
  return data
}
