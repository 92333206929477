import { Flex, Image } from '@chakra-ui/react'
import LogoSethas from '../../assets/logo_sethasrn.png'

export function RNSethas() {
  return (
    <Flex
      as="footer"
      align={'center'}
      justify={'center'}
      px={'8'}
      width={'100%'}
    >
      <Image
        height={{ base: '2.72625rem', md: '4.089375rem' }}
        src={LogoSethas}
        alt="Logo da Secretaria de Estado do Trabalho, da Habitação e da Assistência Social (SETHAS)"
      />
    </Flex>
  )
}
