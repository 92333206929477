import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  SignInCredentials,
  SignInFormData,
  SignInFormValidatorSchema,
  ServiceLogin,
} from '../../../logic/core/login'
import { jwtDecode, useAuth } from '../../../data/context/AuthContext'

export function useLogin() {
  const { signIn } = useAuth()
  const navigateTo = useNavigate()
  const { login } = ServiceLogin

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormData>({
    resolver: yupResolver(SignInFormValidatorSchema),
  })

  const handleSignIn: SubmitHandler<SignInFormData> = async (values, event) => {
    event?.preventDefault()
    await new Promise((resolve) => setTimeout(resolve, 2000))

    try {
      const credentials = {
        username: values.cpf,
        password: values.password,
      } as SignInCredentials

      const data = await login(credentials)
      const { access, refresh } = data

      const { agentecras_id } = jwtDecode(access)

      if (agentecras_id) {
        signIn(access, refresh)
        toast.success('Login realizado com sucesso!')
        navigateTo('/requests')
      } else {
        toast.error('Você não tem permissão para executar essa ação!')
      }
    } catch (loginRequestError: any) {
      const { detail } = loginRequestError?.response.data
      if (detail) {
        toast.error(`${detail}`)
      } else {
        toast.error('Usuário e/ou senha incorreto(s)')
      }
    }
  }
  return {
    register,
    handleSubmit,
    errors,
    isSubmitting,
    handleSignIn,
  }
}
