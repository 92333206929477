import { Outlet } from 'react-router-dom'
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'

import { Header, Sidebar } from '../components'

export function DefaultLayout() {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <Box>
      <Header />

      <Flex
        width="100%"
        marginBottom={isWideVersion ? '6' : '0'}
        maxWidth="1480"
        minHeight={isWideVersion ? 0 : 'calc(100vh - 100px)'}
        marginX="auto"
        paddingX={isWideVersion ? '6' : '0'}
      >
        <Sidebar />

        <Outlet />
      </Flex>
    </Box>
  )
}
