import { api } from '../../../services'

export interface City {
  id: number
  nome: string
}

export const getCities = async (agenteId: number): Promise<City[]> => {
  try {
    const { data } = await api.get<City[]>(
      `/agentescras/${agenteId}/municipios`,
    )
    return data
  } catch (error) {
    return []
  }
}
