import { Flex, Box, Stack, Divider } from '@chakra-ui/react'

import { StatusItem } from '../../../../components/RequestCard/StatusItem'
import { TextItem } from '../../../../components/RequestCard/TextItem'

import { MaintenanceRequest } from '../../data-fetcher/get-maintenance-requests'

import { CpfFormatting, addFormattingForDate } from '../../../../utils'

interface MaintenanceParecerCardProps {
  request: MaintenanceRequest
}

export function MaintenanceParecerCard({
  request,
  ...rest
}: MaintenanceParecerCardProps) {
  const requestType =
    request?.tipo === 'A'
      ? 'Adoção'
      : request?.tipo === 'O'
      ? 'Óbito'
      : 'Responsável'

  const requestStatus =
    request?.status === 'A'
      ? 'ANÁLISE'
      : request?.status === 'D'
      ? 'DEFERIDA'
      : request?.status === 'I'
      ? 'INDEFERIDA'
      : ''

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      key={request.id}
      rounded="8"
      bgColor="platinum.100"
      overflow="hidden"
      boxShadow="2px 2px 4px rgba(0, 0, 0, 0.25)"
      {...rest}
    >
      <Flex as="header" flexDirection="column" justifyContent="center">
        <StatusItem status={requestStatus} />
      </Flex>
      <Box as="main" px="4" py="4">
        <Stack align="flex-start">
          <TextItem color="blue.500">{request?.nome_orfao}</TextItem>
          <TextItem label="CPF">
            {CpfFormatting.format(request?.cpf_orfao ?? '')}
          </TextItem>
          <TextItem label="Município">{request.municipio_orfao}</TextItem>
          <TextItem label="Solicitado em">
            {addFormattingForDate(request?.created_at ?? '')}
          </TextItem>

          <Divider my="2" borderColor="gray.700" />

          <TextItem label="Tipo de solicitação">{requestType}</TextItem>
        </Stack>
      </Box>
    </Flex>
  )
}
