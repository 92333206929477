import { AxiosResponse } from 'axios'
import { api } from '../../../services'
// import { getToken } from '../../../store'


export const updateTutor = async (
  tutorId: number,
  data: any,
): Promise<AxiosResponse<any, any>> => {
  const url = `tutores/${tutorId}/`
  const response = await api.patch(url, data, {
    // headers: {
    //   Authorization: `Bearer ${getToken()}`,
    // },
  })
  return response
}
