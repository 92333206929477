import {
    Box,
    List,
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    Text
  } from '@chakra-ui/react'
  
  import { FcInfo } from 'react-icons/fc'
  import { Subtitle } from './subtitle'
  
  type SubtitleType = {
    id: number
    name: string
    info: string
  }
  
  interface PropsSubtitles {
    subtitlesIdFilter?: number[]
  }
  
  export function Subtitles({
    subtitlesIdFilter = [1, 2, 3, 4, 5, 6, 7]
  }: PropsSubtitles) {
    const subtitlesList: SubtitleType[] = [
      {
        id: 1,
        name: 'PENDENTE',
        info: 'A solicitação necessita da adição de documentos para poder ser cadastrada no sistema do RN Acolhe.'
      },
      {
        id: 2,
        name: 'CADASTRADA',
        info: 'A solicitação foi cadastrada no sistema do RN Acolhe e está aguardando ser analisada.'
      },
      {
        id: 3,
        name: 'ANÁLISE',
        info: 'A solicitação está sendo analisada e terá um resultado em breve.'
      },
      {
        id: 4,
        name: 'CORREÇÃO',
        info: 'A solicitação foi analisada e foram identificados problemas com os dados ou documentos informados na solicitação (e.g. dados pessoais com erros de ortografia, documentos com má visualização, dentre outros), necessitando a correção para a solicitação ser reanalisada.'
      },
      {
        id: 5,
        name: 'CORRIGIDA',
        info: 'A solicitação teve seus dados ou documentos corrigidos de acordo com os problemas encontrados na análise e está apta a ser reanalisada.'
      },
      {
        id: 6,
        name: 'DEFERIDA',
        info: 'A solicitação foi analisada e aprovada para o recebimento do benefício do RN Acolhe.'
      },
      {
        id: 7,
        name: 'INDEFERIDA',
        info: 'A solicitação foi analisada e identificou-se que ela não está dentro de um ou mais critérios para o recebimento do benefício do RN Acolhe.'
      }
    ]
  
    return (
      <Box
        mb={4}
        rounded="8"
        bgColor={'gray.50'}
        p={2}
        boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton py="2" px="3" rounded="8">
                <Box
                  flex="1"
                  textAlign="left"
                  display={'flex'}
                  alignItems={'center'}
                >
                  <FcInfo />
                  <Text ml={1}>Legendas</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <List spacing={3} mt={2}>
                {subtitlesList.map(({ id, name, info}) =>
                  subtitlesIdFilter.includes(id) ? (
                    <Subtitle
                      key={id}
                      name={name}
                      info={info}
                    />
                  ) : (
                    ''
                  )
                )}
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    )
  }
  