import { ReactElement } from 'react'
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'

import { DefaultLayout } from '../layouts/DefaultLayout'
import { RedefinePassword } from '../pages/RedefinePassword'
import { RequestsList } from '../pages/RequestsList'
import { TutoresList } from '../pages/TutoresList'
import { SignIn } from '../pages'
import { isTokenExpired } from '../store'
import { TutorDetail } from '../pages/TutorDetail'
import { MaintenanceRequestsList } from '../pages/MaintenanceResquestList'
import { OrfaosList } from '../pages/OrfaosList'
import { OrfaoDetail } from '../pages/OrfaoDetail'

import { AttachmentsProvider } from '../data/context/AttachmentContext'

interface PrivateRouteProps {
  children: ReactElement
  redirectTo: string
}

function PrivateRoute({ children, redirectTo }: PrivateRouteProps) {
  return isTokenExpired() ? children : <Navigate to={redirectTo} />
}

export function Routes() {
  return (
    <ReactRoutes>
      <Route index element={<SignIn />} />
      <Route path="redefinepassword" element={<RedefinePassword />} />
      <Route
        path="requests"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<RequestsList />} />
      </Route>
      <Route
        path="maintenance-requests"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<MaintenanceRequestsList />} />
        <Route path="*" element={<MaintenanceRequestsList />} />
      </Route>
      <Route
        path="tutores"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<TutoresList />} />
      </Route>
      <Route
        path="tutor/:id"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<TutorDetail />} />
      </Route>
      <Route
        path="orfaos"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<OrfaosList />} />
      </Route>
      <Route
        path="orfao/:orfaoId"
        element={
          <PrivateRoute redirectTo="/">
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <AttachmentsProvider>
              <OrfaoDetail />
            </AttachmentsProvider>
          }
        />
      </Route>
    </ReactRoutes>
  )
}
