import { api } from '../../../services'

interface tutorResponsavelProps {
  id: number
  nome: string
  cpf: string
  telefone: string
}

interface instituicaoResponsavelProps {
  id: number
  nome: string
  cnpj: string
}

export interface Orfao {
  id: number
  tutor_responsavel: tutorResponsavelProps | null
  instituicao_responsavel: instituicaoResponsavelProps | null
  nome: string
  cpf: string
  nis: string
  sexo: string
  data_nascimento: string
  recebe_beneficio: boolean
  fonte_beneficio: string
  valor_beneficio: number
  adotado: boolean
  falecido: boolean
}

export const getOrfao = async (id: number): Promise<Orfao> => {
  const url = `/orfaos/${id}/`
  const { data } = await api.get<Orfao>(url)
  return data
}
