import { get } from 'http'
import { useQuery } from 'react-query'
import { getOrfao } from '../data-fetcher/get-orfao'

export interface OrfaoRequest {
  orfaoId: number
}

export const useOrfao = ({ orfaoId }: OrfaoRequest) => {
  return useQuery(['orfao', orfaoId], async () => getOrfao(orfaoId), {
    refetchOnWindowFocus: true,
  })
}
