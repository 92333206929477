import { useNavigate } from 'react-router-dom'
import { TextItem } from '../../../components'
import { Orfao } from '../data-fetcher/get-orfaos'
import { Flex, Box, Button, Stack, Divider } from '@chakra-ui/react'
import { CpfFormatting } from '../../../utils'

interface OrfaoCardProps {
  orfao: Orfao
}

export function OrfaoCard({ orfao, ...rest }: OrfaoCardProps) {
  const Navigate = useNavigate()

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      rounded="8"
      bgColor="platinum.100"
      overflow="hidden"
      boxShadow="2px 2px 4px rgba(0, 0, 0, 0.25)"
      {...rest}
    >
      <Box as="main" px="4" py="4">
        <Stack>
          <TextItem color="blue.500">{orfao.nome}</TextItem>
          <TextItem label="CPF">{CpfFormatting.format(orfao.cpf)}</TextItem>

          <Divider my="2" borderColor="gray.700" />
        </Stack>
      </Box>

      <Flex
        as="footer"
        flexDirection="column"
        alignItems="center"
        px="4"
        py="4"
      >
        <Button
          title="Detalhar orfão"
          type="button"
          variant="solid"
          color="platinum.50"
          size="sm"
          minWidth="fit-content"
          bgColor="blue.100"
          _hover={{ bgColor: 'blue.300' }}
          onClick={() => {
            Navigate(`/orfao/${orfao.id}`)
          }}
        >
          Detalhar
        </Button>
      </Flex>
    </Flex>
  )
}
