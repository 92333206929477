import { api } from '../../../services'
import { MaintenancesRequest } from '../hook/useMaintenacesRequest'

export interface MaintenanceRequest {
  agentecras_id: number
  cpf_orfao: string
  created_at: string
  documento: string
  id: number
  justificativa: string
  municipio_orfao: string
  nome_orfao: string
  orfao: number
  status: string
  tipo: string
}

interface Response {
  count: number
  next: string
  previous: string
  results: MaintenanceRequest[]
}

export const getMaintenanceRequests = async ({
  currentPage,
  perPage,
}: MaintenancesRequest): Promise<Response> => {
  const url = `/solicitacoes-manutencao?limit=${perPage}&offset=${
    (currentPage - 1) * perPage
  }`

  const {
    data: { count, results, next, previous },
  } = await api.get<Response>(url)

  return { count, results, next, previous }
}
