import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Radio,
  RadioGroup,
  Spinner,
  useBreakpointValue,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import {
  Alert,
  Breadcrumb,
  Content,
  FormControlItem,
  Input,
  SectionDivider,
  SectionForm,
  SectionSubtitle,
  SectionTitle,
} from '../../components'
import { useParams } from 'react-router-dom'
import { useOrfao } from './hook/useOrfao'
import {
  CpfFormatting,
  NIS,
  addFormattingForDate,
  addFormattingForPrice,
  TelefoneFormatting,
  CnpjFormatting,
} from '../../utils'
import { RiSwapLine, RiInformationLine } from 'react-icons/ri'
import { ModalUpdateGuardian } from './modals/ModalUpdateGuardian'
import { ModalReportDeath } from './modals/ModalReportDeath'
import { ModalReportAdoption } from './modals/ModalReportAdoption'

export function OrfaoDetail() {
  const { orfaoId } = useParams()
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  const {
    data: orfao,
    isLoading,
    error,
    isFetching,
  } = useOrfao({ orfaoId: Number(orfaoId) })

  const {
    onOpen: onOpenModalUpdateResponsavel,
    onClose: onCloseModalUpdateResponsavel,
    isOpen: isOpenModalUpdateResponsavel,
  } = useDisclosure()

  const {
    onOpen: onOpenModalReportDeath,
    onClose: onCloseModalReportDeath,
    isOpen: isOpenModalReportDeath,
  } = useDisclosure()

  const {
    onOpen: onOpenModalReportAdoption,
    onClose: onCloseModalReportAdoption,
    isOpen: isOpenModalReportAdoption,
  } = useDisclosure()

  const genero = orfao?.sexo === 'MASCULINO' ? '1' : '2'
  const recebeBeneficio = orfao?.recebe_beneficio === true ? '1' : '2'
  const isTutorResponsavel = !!orfao?.tutor_responsavel
  const isInstituicaoResponsavel = !!orfao?.instituicao_responsavel

  const tooltipMessage = orfao?.falecido
    ? 'Infelizmente, o órfão faleceu'
    : orfao?.adotado
    ? 'O órfão joi adotado'
    : ''
  const tooltipColor = orfao?.falecido
    ? 'red.600'
    : orfao?.adotado
    ? 'orange.600'
    : ''

  document.title = 'RN-ACOLHE - Detalhar órfão'

  return (
    <Content>
      <>
        <ModalUpdateGuardian
          isOpen={isOpenModalUpdateResponsavel}
          onClose={onCloseModalUpdateResponsavel}
          orfaoId={Number(orfaoId)}
        />

        <ModalReportDeath
          isOpen={isOpenModalReportDeath}
          onClose={onCloseModalReportDeath}
          orphanId={orfaoId}
        />

        <ModalReportAdoption
          isOpen={isOpenModalReportAdoption}
          onClose={onCloseModalReportAdoption}
          orphanId={orfaoId}
        />

        <Breadcrumb
          PreviousPaths={[{ id: '1', name: 'Órfãos', url: '/orfaos' }]}
          currentPath="Detalhar órfão"
        />
        <Heading size="lg" fontWeight="normal">
          Detalhar Órfão
          {!isLoading && isFetching && (
            <Spinner marginLeft="4" size="sm" color="blue.300" />
          )}
        </Heading>

        {isLoading && (
          <Flex justifyContent="center" align="center">
            <Spinner color="blue.300" />
          </Flex>
        )}

        {error && (
          <Alert
            variant="solid"
            type="error"
            title="Ocorreu um erro"
            message="Não encontramos nenhum tutor."
          />
        )}

        <Flex flexDirection={'column'}>
          <SectionForm>
            <SectionTitle>Dados pessoais</SectionTitle>
            <SectionSubtitle>Dados pessoais do órfão</SectionSubtitle>
            <SectionDivider />
          </SectionForm>
          <Grid
            templateAreas={{
              base: `"nome" "cpf"`,
              sm: `"nome cpf"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: '65.887% 1fr',
            }}
            gap={'2rem'}
            marginBottom={'2rem'}
            w={'100%'}
          >
            <GridItem area={'nome'}>
              <Input
                label="Nome completo"
                type={'text'}
                placeholder={orfao?.nome}
                isReadOnly
              />
            </GridItem>

            <GridItem area={'cpf'}>
              <Input
                label="CPF"
                type={'text'}
                placeholder={CpfFormatting.format(orfao?.cpf ?? '')}
                isReadOnly
              />
            </GridItem>
          </Grid>
          <Grid
            templateAreas={{
              base: `"genero" "dataNascimento" "NIS"`,
              sm: `"genero dataNascimento NIS"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            w={'100%'}
          >
            <GridItem area={'genero'}>
              <FormControlItem label="Gênero">
                <RadioGroup defaultValue={genero}>
                  <Flex h={'2.5rem'} gap={'1rem'}>
                    <Radio value="1" isReadOnly>
                      Masculino
                    </Radio>
                    <Radio value="2" isReadOnly>
                      Feminino
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControlItem>
            </GridItem>

            <GridItem area={'dataNascimento'}>
              <Input
                label="Data de nascimento"
                type={'text'}
                placeholder={addFormattingForDate(orfao?.data_nascimento ?? '')}
                isReadOnly
              />
            </GridItem>

            <GridItem area={'NIS'}>
              <Input
                label="NIS"
                type={'text'}
                placeholder={NIS.addFormattingForNIS(orfao?.nis ?? '')}
                isReadOnly
              />
            </GridItem>
          </Grid>

          {isTutorResponsavel ? (
            <>
              <SectionForm>
                <SectionTitle>Dados do Tutor responsavel</SectionTitle>
                <SectionSubtitle>
                  Dados do Tutor responsavel pelo órfão
                </SectionSubtitle>
                <SectionDivider />
              </SectionForm>

              <Grid
                templateAreas={{
                  base: `"nomeGuardiao" "documentoGuardiao" "telefoneGuardiao"`,
                  sm: `"nomeGuardiao nomeGuardiao" "documentoGuardiao telefoneGuardiao"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'nomeGuardiao'}>
                  <Input
                    label={'Nome do tutor'}
                    type={'text'}
                    placeholder={orfao?.tutor_responsavel?.nome}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'documentoGuardiao'}>
                  <Input
                    label={'CPF do tutor'}
                    type={'text'}
                    placeholder={CpfFormatting.format(
                      orfao?.tutor_responsavel?.cpf ?? '',
                    )}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'telefoneGuardiao'}>
                  <Input
                    label={'Telefone do tutor'}
                    type={'text'}
                    placeholder={TelefoneFormatting.format(
                      orfao?.tutor_responsavel?.telefone,
                    )}
                    isReadOnly
                  />
                </GridItem>
              </Grid>
            </>
          ) : isInstituicaoResponsavel ? (
            <>
              <SectionForm>
                <SectionTitle>Dados da instituição responsavel</SectionTitle>
                <SectionSubtitle>
                  Dados da instituição responsavel pelo órfão
                </SectionSubtitle>
                <SectionDivider />
              </SectionForm>
              <Flex w={'100%'}>
                <Input
                  label={'Nome da instituição'}
                  type={'text'}
                  placeholder={orfao?.instituicao_responsavel?.nome}
                  isReadOnly
                />
              </Flex>
            </>
          ) : null}

          <SectionForm>
            <SectionTitle>Fonte de renda</SectionTitle>
            <SectionSubtitle>Fonte de renda do órfão</SectionSubtitle>
            <SectionDivider />
          </SectionForm>

          <Grid
            templateAreas={{
              base: `"recebeBeneficio" "fonteBeneficio" "valorBeneficio"`,
              sm: `"recebeBeneficio fonteBeneficio valorBeneficio"`,
            }}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(3, 1fr)',
            }}
            gap={'2rem'}
            marginBottom={'2rem'}
            w={'100%'}
          >
            <GridItem area={'recebeBeneficio'}>
              <FormControlItem label="Recebe benefício">
                <RadioGroup defaultValue={recebeBeneficio}>
                  <Flex h={'2.5rem'} gap={'1rem'}>
                    <Radio value="1" isReadOnly>
                      Sim
                    </Radio>
                    <Radio value="2" isReadOnly>
                      Não
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControlItem>
            </GridItem>

            <GridItem area={'fonteBeneficio'}>
              <Input
                label="Fonte de benefício"
                type={'text'}
                placeholder={orfao?.fonte_beneficio}
                isReadOnly
              />
            </GridItem>

            <GridItem area={'valorBeneficio'}>
              <Input
                label="Valor do benefício"
                type={'text'}
                placeholder={
                  orfao?.recebe_beneficio
                    ? addFormattingForPrice(Number(orfao?.valor_beneficio) ?? 0)
                    : ''
                }
                isReadOnly
              />
            </GridItem>
          </Grid>
        </Flex>
      </>
      <Flex
        gap={'1rem'}
        flex={'1'}
        direction={isWideVersion ? 'row' : 'column'}
        justifyContent={isWideVersion ? 'flex-end' : 'center'}
        mt={isWideVersion ? '5.5rem' : '2rem'}
      >
        <Tooltip
          hasArrow
          label={tooltipMessage}
          bg={tooltipColor}
          fontSize={'md'}
          isDisabled={!orfao?.adotado && !orfao?.falecido}
        >
          <Button
            leftIcon={<RiSwapLine />}
            bgColor={'blue.100'}
            _hover={{ bgColor: 'blue.300' }}
            color={'white'}
            onClick={() => onOpenModalUpdateResponsavel()}
            w={isWideVersion ? 'auto' : '100%'}
            disabled={orfao?.adotado || orfao?.falecido}
          >
            Atualizar responsável
          </Button>
        </Tooltip>
        <Tooltip
          hasArrow
          label={tooltipMessage}
          bg={tooltipColor}
          fontSize={'md'}
          isDisabled={!orfao?.adotado && !orfao?.falecido}
        >
          <Button
            leftIcon={<RiInformationLine />}
            bgColor={'orange.400'}
            _hover={{ bgColor: 'orange.600' }}
            color={'white'}
            onClick={onOpenModalReportAdoption}
            w={isWideVersion ? 'auto' : '100%'}
            disabled={orfao?.adotado || orfao?.falecido}
          >
            Informar adoção
          </Button>
        </Tooltip>
        <Tooltip
          hasArrow
          label={'Infelizmente, o órfão faleceu'}
          bg="red.600"
          fontSize={'md'}
          isDisabled={!orfao?.falecido}
        >
          <Button
            leftIcon={<RiInformationLine />}
            bgColor={'red.500'}
            _hover={{ bgColor: 'red.700' }}
            color={'white'}
            onClick={onOpenModalReportDeath}
            w={isWideVersion ? 'auto' : '100%'}
            disabled={orfao?.falecido}
          >
            Informar óbito
          </Button>
        </Tooltip>
      </Flex>
    </Content>
  )
}
