import { api } from '../../../services'
import { TutoresRequest } from '../hook/useTutores'

export interface Tutor {
  id: number
  nome: string
  cpf: string
  municipio: string
  rg: string
  email: string
  telefone: string
  data_nascimento: string
}

interface Response {
  count: number
  next: string
  previous: string
  results: Tutor[]
}

export const getTutores = async ({
  currentPage,
  perPage,
}: TutoresRequest): Promise<Response> => {
  const url = `/tutores?limit=${perPage}&offset=${
    (currentPage - 1) * perPage
  }`

  const {
    data: { count, results, next, previous },
  } = await api.get<Response>(url)

  return { count, results, next, previous }
}
