import {
  Flex,
  Box,
  Button,
  Stack,
  Divider,
  useDisclosure,
} from '@chakra-ui/react'

import { StatusItem } from '../../../../components/RequestCard/StatusItem'
import { TextItem } from '../../../../components/RequestCard/TextItem'

import { CpfFormatting, addFormattingForDate } from '../../../../utils'
import { MaintenanceParecerModal } from '../MaintenanceParecerModal'
import { MaintenanceRequest } from '../../data-fetcher/get-maintenance-requests'

interface MaintenanceRequestCardProps {
  request: MaintenanceRequest
}

export function MaintenanceRequestCard({
  request,
  ...rest
}: MaintenanceRequestCardProps) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const showVisualizarParecer =
    request?.status === 'D' || request?.status === 'I'

  const requestStatus =
    request?.status === 'A'
      ? 'ANÁLISE'
      : request?.status === 'D'
      ? 'DEFERIDA'
      : request?.status === 'I'
      ? 'INDEFERIDA'
      : ''

  const requestType =
    request?.tipo === 'A'
      ? 'Adoção'
      : request?.tipo === 'O'
      ? 'Óbito'
      : 'Responsável'

  return (
    <>
      <MaintenanceParecerModal
        isOpen={isOpen}
        onClose={onClose}
        request={request}
      />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        key={request.id}
        rounded="8"
        bgColor="platinum.100"
        overflow="hidden"
        boxShadow="2px 2px 4px rgba(0, 0, 0, 0.25)"
        {...rest}
      >
        <Flex as="header" flexDirection="column" justifyContent="center">
          <StatusItem status={requestStatus} />
        </Flex>
        <Box as="main" px="4" py="4">
          <Stack align="flex-start">
            <TextItem color="blue.500">{request?.nome_orfao}</TextItem>
            <TextItem label="CPF">
              {CpfFormatting.format(request?.cpf_orfao ?? '')}
            </TextItem>
            <TextItem label="Município">{request?.municipio_orfao}</TextItem>
            <TextItem label="Solicitado em">
              {addFormattingForDate(request?.created_at ?? '')}
            </TextItem>

            <Divider my="2" borderColor="gray.700" />

            <TextItem label="Tipo de solicitação">{requestType}</TextItem>
          </Stack>
        </Box>

        <Flex
          as="footer"
          flexDirection="column"
          alignItems="center"
          px="4"
          pb="4"
        >
          <Button
            disabled={!showVisualizarParecer}
            title="Visualizar parecer técnico da solicitação"
            type="button"
            variant="solid"
            color="platinum.50"
            size="sm"
            minWidth="fit-content"
            bgColor="blue.100"
            _hover={{ bgColor: 'blue.300' }}
            onClick={onOpen}
          >
            Visualizar parecer
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
