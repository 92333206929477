import { api } from '../../../services'
import { OrfaosRequest } from '../hook/useOrfaos'

export interface Orfao {
  id: number
  nome: string
  cpf: string
}

interface Response {
  count: number
  next: string
  previous: string
  results: Orfao[]
}

export const getOrfaos = async ({
  currentPage,
  perPage,
}: OrfaosRequest): Promise<Response> => {
  const url = `/agentecras-orfaos/?limit=${perPage}&offset=${
    (currentPage - 1) * perPage
  }&adotado=false&falecido=false`

  const {
    data: { count, results, next, previous },
  } = await api.get<Response>(url)

  return { count, results, next, previous }
}
