import {
  Alert as AlertBase,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  ChakraProps,
  Flex,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react'

interface AlertProps extends ChakraProps {
  type: 'success' | 'info' | 'warning' | 'error' | 'loading'
  variant: 'solid' | 'subtle' | 'left-accent' | 'top-accent'
  title: string
  message: string
}

export function Alert({
  type = 'info',
  variant = 'solid',
  title,
  message,
}: AlertProps) {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <SimpleGrid flex="1" gap="4" minChildWidth="320px">
      <Flex justify="center" align="center">
        <AlertBase
          status={type}
          variant={variant}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          rounded={isWideVersion ? '8' : 'none'}
        >
          <AlertIcon
            boxSize="2rem" // 32px
          />
          <AlertTitle
            marginTop="4" // 1rem
            marginBottom="1" // 0.25rem
            fontSize="lg"
          >
            {title}
          </AlertTitle>
          <AlertDescription maxWidth="sm">{message}</AlertDescription>
        </AlertBase>
      </Flex>
    </SimpleGrid>
  )
}
