import { useQuery } from 'react-query'
import { getMaintenanceRequests } from '../data-fetcher/get-maintenance-requests'

export interface MaintenancesRequest {
  currentPage: number
  perPage: number
}

export const useMaintenanceRequests = ({
  currentPage,
  perPage,
}: MaintenancesRequest) => {
  return useQuery(
    ['maintenance-requests', currentPage, perPage],
    () => getMaintenanceRequests({ currentPage, perPage }),
    {
      refetchOnWindowFocus: true,
      retry: true,
    },
  )
}
