import { useQuery } from 'react-query'
import { getOrfaos } from '../data-fetcher/get-orfaos'

export interface OrfaosRequest {
  currentPage: number
  perPage: number
}

export const useOrfaos = ({ currentPage, perPage }: OrfaosRequest) => {
  return useQuery(
    ['orfaos', currentPage, perPage],
    () => getOrfaos({ currentPage, perPage }),
    {
      refetchOnWindowFocus: true,
      retry: true,
    },
  )
}
