import { Flex, Box, Button, Stack, Divider } from '@chakra-ui/react'

import { Tutor } from '../../data-fetcher/get-tutores'
import { RiEyeLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { TextItem } from '../../../../components'
import {
  CpfFormatting,
  RgFormatting,
  TelefoneFormatting,
} from '../../../../utils'

interface TutorCardProps {
  tutor: Tutor
}

export function TutorCard({ tutor, ...rest }: TutorCardProps) {
  const Navigate = useNavigate()
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        rounded="8"
        bgColor="platinum.100"
        overflow="hidden"
        boxShadow="2px 2px 4px rgba(0, 0, 0, 0.25)"
        {...rest}
      >
        <Box as="main" px="4" py="4">
          <Stack align="flex-start">
            <TextItem color="blue.500">{tutor.nome}</TextItem>
            <TextItem label="CPF">{CpfFormatting.format(tutor.cpf)}</TextItem>
            <TextItem label="Município">{tutor.municipio}</TextItem>
            <TextItem label="RG">{RgFormatting.format(tutor.rg)}</TextItem>

            <Divider my="2" borderColor="gray.700" />

            <TextItem label="Município">{tutor.email}</TextItem>
            <TextItem label="Telefone">
              {TelefoneFormatting.format(tutor.telefone)}
            </TextItem>
          </Stack>
        </Box>

        <Flex
          as="footer"
          flexDirection="column"
          alignItems="center"
          px="4"
          pb="4"
        >
          <Button
            leftIcon={<RiEyeLine />}
            title="Detalhar tutor"
            type="button"
            variant="solid"
            color="platinum.50"
            size="sm"
            minWidth="fit-content"
            bgColor="blue.100"
            _hover={{ bgColor: 'blue.300' }}
            onClick={() => {
              Navigate(`/tutor/${tutor.id}`)
            }}
          >
            Detalhar
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
