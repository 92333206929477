import axios from 'axios'
import { getToken } from '../../store'
import { interceptor } from './auth-interceptor'

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/api/'
      : 'https://api.rnacolhe.sethas.rn.gov.br/api/',
})


api.interceptors.request.use(
  (config: any) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

api.interceptors.response.use(undefined, interceptor(api))
