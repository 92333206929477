import { useEffect, useState } from 'react'
import { City, getCities } from '../data-fetcher'
import { useAuth } from '../../../data/context/AuthContext'

export const useCities = () => {
  const [cities, setCities] = useState<City[]>([])
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      getCities(user.agentecras_id).then((data) => {
        setCities(data)
      })
    }
  }, [user])

  return cities
}
