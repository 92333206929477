import { useEffect, useState } from 'react'
import { getPurport, Purport } from '../data-fetcher'
import { useAuth } from '../../../data/context/AuthContext'

export const usePurport = (requesId: number, modalIsOpen: boolean) => {
  const [purport, setPurport] = useState<Purport>()
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    if (user && modalIsOpen) {
      setLoading(true)
      getPurport(user.agentecras_id, requesId).then((data) => {
        setPurport(data)
      })
      setLoading(false)
    }
  }, [user, requesId, modalIsOpen])

  return { purport, loading }
}
