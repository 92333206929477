export class CnpjFormatting {
    private static REGEX_FORMAT = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    private static REGEX_UN_FORMAT = /[.-/]/g;
  
    static format(cnpj: string): string {
      return cnpj.replace(CnpjFormatting.REGEX_FORMAT, '$1.$2.$3/$4-$5');
    }
  
    static unFormat(cnpj: string): string {
      return cnpj.replace(CnpjFormatting.REGEX_UN_FORMAT, '');
    }
  }