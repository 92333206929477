import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  Text,
  ModalFooter,
  Button
} from '@chakra-ui/react'

interface ModalBaseProps {
  onConfirm: () => void
  onClose: () => void
  isOpen: boolean
  isSubmitting: boolean
  title: string
  description: string
}

export function ModalConfirm({
  isOpen,
  onClose,
  onConfirm,
  isSubmitting,
  title,
  description
}: ModalBaseProps) {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="none"
      size={'sm'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={['center']}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={['center']}>
          <Text>{description}</Text>
        </ModalBody>
        <ModalFooter justifyContent={['center']} gap="3">
          <Button
            size={'md'}
            color={'platinum.50'}
            bgColor={'platinum.600'}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            size={'md'}
            color={'platinum.50'}
            bgColor={'green.50'}
            _hover={{ bgColor: 'green.100' }}
            onClick={onConfirm}
            isLoading={isSubmitting}
            loadingText={'Confirmando'}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
