import { Text, TextProps } from '@chakra-ui/react'

type SubTitleProps = TextProps

export function SubTitle({ children }: SubTitleProps) {
  return (
    <Text
      as="span"
      fontSize={'0.75rem'}
      fontWeight={'normal'}
      lineHeight={'0.875rem'}
      color={'gray.400'}
    >
      {children}
    </Text>
  )
}
