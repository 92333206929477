import { useState, useEffect } from 'react'
import { Flex, Heading, SimpleGrid, Spinner } from '@chakra-ui/react'
import { MaintenanceRequestCard } from '../MaintenanceResquestList/components/MaintenanceRequestCard'
import { Alert, Content, Pagination, Subtitles } from '../../components'
import { useMaintenanceRequests } from './hook/useMaintenacesRequest'

export function MaintenanceRequestsList() {
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 9

  const {
    error,
    isLoading,
    isFetching,
    data: maintenanceRequests,
  } = useMaintenanceRequests({
    currentPage,
    perPage,
  })

  const hasNoMaintenanceRequest = Boolean(!maintenanceRequests?.results.length)
  const hasMaintenanceRequest = maintenanceRequests?.results.length

  document.title = 'Agente CRAS - Solicitações de manutenção do benefício'

  return (
    <Content>
      <>
        <Heading size="lg" fontWeight="normal" mb="40px">
          Solicitações de manutenção
          {!isLoading && isFetching && (
            <Spinner marginLeft="4" size="sm" color="blue.300" />
          )}
        </Heading>

        <Subtitles subtitlesIdFilter={[3, 6, 7]} />

        {isLoading && (
          <Flex justifyContent="center" align="center">
            <Spinner color="blue.300" />
          </Flex>
        )}

        {hasNoMaintenanceRequest && error && (
          <Alert
            variant="solid"
            type="error"
            title="Ocorreu um erro"
            message="Não foi possível listar as solicitações de manutenção do benefício."
          />
        )}

        {!error && !isLoading && hasNoMaintenanceRequest && (
          <Alert
            variant="solid"
            type="warning"
            title="Nenhuma solicitação encontrada"
            message="Não encontramos nenhuma solicitações de manutenção do benefício."
          />
        )}

        {hasMaintenanceRequest !== undefined && hasMaintenanceRequest > 0 && (
          <SimpleGrid flex="1" gap="4" minChildWidth="320px">
            {maintenanceRequests?.results.map((maintenanceRequest) => (
              <MaintenanceRequestCard
                key={maintenanceRequest.id}
                request={maintenanceRequest}
              />
            ))}
          </SimpleGrid>
        )}

        {hasMaintenanceRequest !== undefined &&
          maintenanceRequests &&
          maintenanceRequests?.count > 9 && (
            <Pagination
              totalCountOfRegisters={maintenanceRequests?.count}
              registersPerPage={perPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              titlePage="solicitações"
            />
          )}
      </>
    </Content>
  )
}
