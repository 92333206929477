import { createContext, ReactNode, useContext } from 'react'
import { useFilters } from '../hook'

type RequestFiltersData = {
  statusId: string
  cityId: string
  guardiao: string
  instituicaoId: string
  CPFTutor: string
  CPFOrfao: string
  observerChangeInSelectStatus: (value: string) => void
  observerChangeInSelectCity: (value: string) => void
  observerChangeInSelectGuardioes: (value: string) => void
  observerChangeInSelectInstituicoes: (value: string) => void
  observerChangeInInputCPFTutor: (value: string) => void
  observerChangeInInputCPFOrfao: (value: string) => void
  handleButtonDelete: () => void
}

const RequestFiltersContext = createContext({} as RequestFiltersData)

type RequestFiltersProps = {
  children: ReactNode
}

export function RequestFiltersProvider({ children }: RequestFiltersProps) {
  const {
    statusId,
    cityId,
    guardiao,
    instituicaoId,
    CPFTutor,
    CPFOrfao,
    observerChangeInSelectStatus,
    observerChangeInSelectCity,
    observerChangeInSelectGuardioes,
    observerChangeInSelectInstituicoes,
    observerChangeInInputCPFTutor,
    observerChangeInInputCPFOrfao,
    handleButtonDelete,
  } = useFilters()
  return (
    <RequestFiltersContext.Provider
      value={{
        statusId,
        cityId,
        guardiao,
        instituicaoId,
        CPFTutor,
        CPFOrfao,
        observerChangeInSelectStatus,
        observerChangeInSelectCity,
        observerChangeInSelectGuardioes,
        observerChangeInSelectInstituicoes,
        observerChangeInInputCPFTutor,
        observerChangeInInputCPFOrfao,
        handleButtonDelete,
      }}
    >
      {children}
    </RequestFiltersContext.Provider>
  )
}

export function useRequestFilters() {
  const context = useContext(RequestFiltersContext)

  if (!context) {
    throw new Error(
      'useRequestFilters deve ser usado em um  RequestFiltersProvider',
    )
  }

  return context
}
