import {
  Flex,
  Icon,
  IconButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Modal,
  ModalConfirm,
  SecondaryButton,
  SectionDivider,
  TertiaryButton,
} from '../../../../components'
import { RiCloseLine } from 'react-icons/ri'
import { Card } from '../../components'

import { useContext } from 'react'
import { AttachmentsContext } from '../../../../data/context/AttachmentContext'

export function ModalReportDeath({ isOpen, onClose, orphanId }: any) {
  const context = useContext(AttachmentsContext)
  const {
    handleFileAttachmentReportDeath,
    setNameFile,
    setValue,
    documento,
    setDocumento,
    loading,
    loadingSubmitting,
  } = context

  const modalSize = useBreakpointValue({ base: 'xs', md: '4xl', '3xl': '5xl' })
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  const onCloseModal = () => {
    setNameFile('')
    setValue('documento', null)
    setDocumento(null)
    onClose()
  }

  const attachment = {
    label: 'Certidão de óbito',
    required: true,
    type: 2,
  }

  const handleOnCloseModals = () => {
    onCloseConfirm()
    onCloseModal()
  }

  return (
    <>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={() =>
          handleFileAttachmentReportDeath(orphanId, () => handleOnCloseModals())
        }
        isSubmitting={loadingSubmitting || loading}
        title="Confirmar solicitação"
        description="Deseja realmente informar o óbito do órfão? Esta ação não poderá ser desfeita. E irá gerar uma nova solicitação, que será analisada."
      />
      <Modal.Root isOpen={isOpen} onClose={onCloseModal} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
          <Modal.Header>
            <Flex
              justify="space-between"
              alignItems="center"
              width="100%"
              gap="10px"
            >
              <Flex
                justify="center"
                align="flex-start"
                textTransform="uppercase"
              >
                INFORMAR ÓBITO
              </Flex>
              <IconButton
                aria-label="Fechar modal"
                color="blackAlpha.900"
                icon={<Icon as={RiCloseLine} fontSize="1.5rem" />}
                mt="0.20rem"
                size="xs"
                variant="unstated"
                onClick={onCloseModal}
              />
            </Flex>
            <SectionDivider />
          </Modal.Header>
          <Modal.Body>
            <Card infoCard={attachment} />
          </Modal.Body>
          <Modal.Footer>
            <TertiaryButton onClick={onCloseModal}>Fechar</TertiaryButton>
            <SecondaryButton
              onClick={onOpenConfirm}
              loadingText="Confirmando"
              disabled={!!!documento || loading}
            >
              Confirmar
            </SecondaryButton>
          </Modal.Footer>
        </ModalContent>
      </Modal.Root>
    </>
  )
}
