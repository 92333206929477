import { useQuery } from 'react-query'
import { getTutor } from '../data-fetcher/get-tutor'

export interface SimpleTutorRequest {
  tutorCPF: number
}

export const useTutor = ({ tutorCPF }: SimpleTutorRequest) => {
  return useQuery(['simpleTutor', tutorCPF], 
  () => getTutor(tutorCPF), 
  {
    refetchOnWindowFocus: true,
    retry: true,
    enabled: !!tutorCPF,
  },
  )
}

