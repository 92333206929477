import { Flex, Heading, SimpleGrid, Spinner } from '@chakra-ui/react'
import { Alert, Content, Pagination } from '../../components'
import { OrfaoCard } from './components/OrfaoCard'
import { useState } from 'react'
import { useOrfaos } from './hook/useOrfaos'

export function OrfaosList() {
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 9

  const {
    error,
    isLoading,
    isFetching,
    data: orfaos,
  } = useOrfaos({
    currentPage,
    perPage,
  })

  return (
    <Content>
      <>
        <Heading size="lg" fontWeight="normal" mb="40px">
          Orfãos
          {!isLoading && isFetching && (
            <Spinner marginLeft="4" size="sm" color="blue.300" />
          )}
        </Heading>

        {isLoading && (
          <Flex justifyContent="center" align="center">
            <Spinner color="blue.300" />
          </Flex>
        )}

        {error && (
          <Alert
            variant="solid"
            type="error"
            title="Ocorreu um erro"
            message="Não foi possível listar os orfãos."
          />
        )}

        {!error && !isLoading && !orfaos?.results?.length && (
          <Alert
            variant="solid"
            type="warning"
            title="Nenhum orfão encontrado"
            message="Não encontramos nenhum orfão."
          />
        )}

        {orfaos?.results && orfaos?.results?.length > 0 && (
          <SimpleGrid flex="1" gap="4" minChildWidth="320px">
            {orfaos?.results?.map((orfao) => (
              <OrfaoCard key={orfao.id} orfao={orfao} />
            ))}
          </SimpleGrid>
        )}

        <Pagination
          totalCountOfRegisters={orfaos?.count}
          registersPerPage={perPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          titlePage="orfaos"
        />
      </>
    </Content>
  )
}
