import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import {
  Alert,
  Content,
  SectionDivider,
  SectionForm,
  SectionSubtitle,
  SectionTitle,
  Input,
  Breadcrumb,
} from '../../components'
import { useTutor } from './hook/useTutor'
import {
  CepFormatting,
  CpfFormatting,
  RgFormatting,
  TelefoneFormatting,
} from '../../utils'
import { BsPencilSquare } from 'react-icons/bs'
import { ModalDadosPessoais } from './components/ModalDadosPessoais'
import { ModalDadosEndereco } from './components/ModalDadosEndereco'

export function TutorDetail() {
  const { id } = useParams()

  const {
    error,
    isLoading,
    isFetching,
    data: tutor,
  } = useTutor({ tutorId: Number(id) })
  const hasNoTutor = Boolean(!tutor)

  const {
    onOpen: onOpenPessoais,
    onClose: onClosePessoais,
    isOpen: isOpenPessoais,
  } = useDisclosure()
  const {
    onOpen: onOpenEndereco,
    onClose: onCloseEndereco,
    isOpen: isOpenEndereco,
  } = useDisclosure()
  document.title = 'RN-ACOLHE - Detalhar tutor'
  console.log(CepFormatting.format(tutor?.endereco.cep))
  return (
    <Content>
      <>
        <ModalDadosPessoais
          data={tutor}
          title={'Dados pessoais'}
          isOpen={isOpenPessoais}
          onClose={onClosePessoais}
        />
        <ModalDadosEndereco
          data={tutor}
          title={'Endereço'}
          isOpen={isOpenEndereco}
          onClose={onCloseEndereco}
        />

        <Breadcrumb
          PreviousPaths={[{ id: '1', name: 'Tutores', url: '/tutores' }]}
          currentPath="Detalhar tutor"
        />
        <Heading size="lg" fontWeight="normal">
          Detalhar Tutor
          {!isLoading && isFetching && (
            <Spinner marginLeft="4" size="sm" color="blue.300" />
          )}
        </Heading>

        {isLoading && (
          <Flex justifyContent="center" align="center">
            <Spinner color="blue.300" />
          </Flex>
        )}

        {hasNoTutor && error && (
          <Alert
            variant="solid"
            type="error"
            title="Ocorreu um erro"
            message="Não encontramos nenhum tutor."
          />
        )}

        {!error && !isLoading && hasNoTutor && (
          <Alert
            variant="solid"
            type="warning"
            title="Nenhum tutor encontrado"
            message="Não foi possível carregar os dados do tutor."
          />
        )}

        {!hasNoTutor && (
          <>
            <Flex flexDirection={'column'}>
              <SectionForm icon={BsPencilSquare} onOpen={onOpenPessoais}>
                <SectionTitle>Dados de pessoais</SectionTitle>
                <SectionSubtitle>Dados pessoais do tutor</SectionSubtitle>
                <SectionDivider />
              </SectionForm>

              <Grid
                templateAreas={{
                  base: `"nome" "cpf"`,
                  sm: `"nome cpf"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: '65.887% 1fr',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'nome'}>
                  <Input
                    label="Nome completo"
                    type={'text'}
                    placeholder={tutor?.nome}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'cpf'}>
                  <Input
                    label="CPF"
                    type={'text'}
                    placeholder={CpfFormatting.format(tutor?.cpf ?? '')}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"numeroRg" "orgaoRg"`,
                  sm: `"numeroRg orgaoRg"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: '1fr 1fr',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'numeroRg'}>
                  <Input
                    label="Número do RG"
                    type={'text'}
                    placeholder={RgFormatting.format(tutor?.rg)}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'orgaoRg'}>
                  <Input
                    label="Órgão expedidor do RG"
                    type={'text'}
                    placeholder={tutor?.uf_orgao_expedidor}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"email" "telefone" "telAlt"`,
                  sm: `"email email" "telefone telAlt"`,
                  md: `"email telefone telAlt"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'email'}>
                  <Input
                    label="E-mail"
                    type={'email'}
                    placeholder={tutor?.email}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'telefone'}>
                  <Input
                    label="Telefone"
                    type={'text'}
                    placeholder={TelefoneFormatting.format(tutor?.telefone)}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'telAlt'}>
                  <Input
                    label="Telefone/Cel alternativo"
                    type={'text'}
                    placeholder={TelefoneFormatting.format(
                      tutor?.telefone_alternativo,
                    )}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <SectionForm icon={BsPencilSquare} onOpen={onOpenEndereco}>
                <SectionTitle>Dados de endereço</SectionTitle>
                <SectionSubtitle>Dados de endereço do tutor</SectionSubtitle>
                <SectionDivider />
              </SectionForm>

              <Grid
                templateAreas={{
                  base: `"cep" "cidade" "bairro"`,
                  sm: `"cep cep" "cidade bairro"`,
                  md: `"cep cidade bairro"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                marginBottom={'2rem'}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'cep'}>
                  <Input
                    label="CEP"
                    type={'text'}
                    placeholder={CepFormatting.format(tutor?.endereco.cep)}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'cidade'}>
                  <Input
                    label="Cidade"
                    type={'text'}
                    placeholder={tutor?.endereco.municipio.toString()}
                    isReadOnly
                  />
                </GridItem>

                <GridItem area={'bairro'}>
                  <Input
                    label="Bairro"
                    type={'text'}
                    placeholder={tutor?.endereco.bairro}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"logradouro" "numero" "uf"`,
                  sm: `"logradouro logradouro" "numero uf"`,
                  md: `"logradouro numero uf"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                marginBottom={'2rem'}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'logradouro'}>
                  <Input
                    label="Logradouro"
                    type={'text'}
                    placeholder={tutor?.endereco.logradouro}
                    isReadOnly
                  />
                </GridItem>
                <GridItem area={'numero'}>
                  <Input
                    label="Número"
                    type={'text'}
                    placeholder={tutor?.endereco.numero}
                    isReadOnly
                  />
                </GridItem>
                <GridItem area={'uf'}>
                  <Input
                    label="UF"
                    type={'text'}
                    placeholder={tutor?.endereco.uf}
                    isReadOnly
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"complemento" "pontoRef"`,
                  sm: `"complemento pontoRef"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: '1fr 1fr',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'complemento'}>
                  <Input
                    label="Complemento"
                    type={'text'}
                    placeholder={tutor?.endereco.complemento}
                    isReadOnly
                    _required={false}
                  />
                </GridItem>

                <GridItem area={'pontoRef'}>
                  <Input
                    label="Ponto de referência"
                    type={'text'}
                    placeholder={tutor?.endereco.ponto_de_referencia}
                    isReadOnly
                    _required={false}
                  />
                </GridItem>
              </Grid>
            </Flex>
          </>
        )}
      </>
    </Content>
  )
}
