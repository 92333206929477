import { Badge } from '@chakra-ui/react'

import { getBGColorByStatus } from '../../../utils/get-bg-color-by-status'
import { getColorStatus } from '../../../utils/get-color-status'
import { getTitleByStatus } from '../../../utils/get-title-by-status'

interface StatusItemProps {
  status: string
}
export function StatusItem({ status, ...rest }: StatusItemProps) {
  return (
    <Badge
      title={getTitleByStatus(status)}
      color={getColorStatus(status)}
      textAlign="center"
      fontSize="sm"
      variant="solid"
      bgColor={getBGColorByStatus(status)}
      px="2"
      py="2"
      {...rest}
    >
      {status}
    </Badge>
  )
}
