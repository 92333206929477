import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { RiCloseLine } from 'react-icons/ri'
import {
  Input,
  Modal,
  ModalConfirm,
  SecondaryButton,
  SectionDivider,
  Select,
  TertiaryButton,
} from '../../../components'
import { getCities, getCityIdByName } from '../../../utils/get-cities'
import { useUpdateEnderecoTutor } from '../hook/useUpdateEnderecoTutor'
import { queryClient } from '../../../services/react-query/query-client'

export function ModalDadosEndereco({ data, title, onConfirm, ...props }: any) {
  const { register, handleSubmit, updateEndereco, errors, isSubmitting } =
    useUpdateEnderecoTutor(data)
  const modalSize = useBreakpointValue({ base: 'xs', md: '3xl', '3xl': '4xl' })
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  const handleConfirm = async () => {
    await handleSubmit(updateEndereco)()
    queryClient.invalidateQueries('tutor')
    props.onClose()
    onCloseConfirm()
  }

  const counties = getCities()

  return (
    <>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isSubmitting={isSubmitting}
        title={'Confirmar atualização'}
        description={
          'Após a confirmação, os campos modificados serão salvos. Deseja realmente confirmar essa alteração?'
        }
        onConfirm={handleConfirm}
      />

      <Modal.Root size={modalSize} {...props} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent overflowY={'auto'}>
          <Box>
            <Modal.Header>
              <Flex
                justify="space-between"
                alignItems="center"
                width="100%"
                gap="10px"
              >
                <Flex
                  justify="center"
                  align="flex-start"
                  textTransform="uppercase"
                >
                  {title}
                </Flex>
                <IconButton
                  aria-label="Fechar modal"
                  color="blackAlpha.900"
                  icon={<Icon as={RiCloseLine} fontSize="1.5rem" />}
                  mt="0.20rem"
                  size="xs"
                  variant="unstated"
                  onClick={props.onClose}
                />
              </Flex>
              <SectionDivider />
            </Modal.Header>

            <Modal.Body>
              <Grid
                templateAreas={{
                  base: `"cep" "municipio" "bairro"`,
                  md: `"cep municipio bairro"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                marginBottom={'2rem'}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'cep'}>
                  <Input
                    label="CEP"
                    type={'text'}
                    mask={'99999-999'}
                    defaultValue={data?.endereco.cep}
                    {...register('cep')}
                    error={errors.cep}
                  />
                </GridItem>

                <GridItem area={'municipio'}>
                  <Select
                    label="Município"
                    {...register('municipio')}
                    error={errors.municipio}
                    defaultValue={getCityIdByName(data?.endereco.municipio)}
                  >
                    {counties
                      .map((county) => (
                        <option key={county.id} value={county.id}>
                          {county.name}
                        </option>
                      ))
                      .sort()}
                  </Select>
                </GridItem>

                <GridItem area={'bairro'}>
                  <Input
                    label="Bairro"
                    type={'text'}
                    defaultValue={data?.endereco.bairro}
                    {...register('bairro')}
                    error={errors.bairro}
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"logradouro" "numero" "uf"`,
                  md: `"logradouro numero uf"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                marginBottom={'2rem'}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'logradouro'}>
                  <Input
                    label="Logradouro"
                    type={'text'}
                    defaultValue={data?.endereco.logradouro}
                    {...register('logradouro')}
                    error={errors.logradouro}
                  />
                </GridItem>
                <GridItem area={'numero'}>
                  <Input
                    label="Número"
                    type={'text'}
                    defaultValue={data?.endereco.numero}
                    {...register('numero')}
                    error={errors.numero}
                  />
                </GridItem>
                <GridItem area={'uf'}>
                  <Input
                    label="UF"
                    type={'text'}
                    defaultValue={data?.endereco.uf}
                    {...register('uf')}
                    error={errors.uf}
                    disabled
                  />
                </GridItem>
              </Grid>

              <Grid
                templateAreas={{
                  base: `"complemento" "pontoRef"`,
                  md: `"complemento pontoRef"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={'2rem'}
                marginBottom={'2rem'}
                w={'100%'}
              >
                <GridItem area={'complemento'}>
                  <Input
                    label="Complemento"
                    type={'text'}
                    defaultValue={data?.endereco.complemento}
                    {...register('complemento')}
                    error={errors.complemento}
                    _required={false}
                  />
                </GridItem>

                <GridItem area={'pontoRef'}>
                  <Input
                    label="Ponto de referência"
                    type={'text'}
                    defaultValue={data?.endereco.ponto_de_referencia}
                    {...register('ponto_de_referencia')}
                    error={errors.ponto_de_referencia}
                    _required={false}
                  />
                </GridItem>
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <TertiaryButton onClick={props.onClose}>Fechar</TertiaryButton>
              <SecondaryButton
                onClick={onOpenConfirm}
                isLoading={isSubmitting}
                loadingText="Confirmando"
                disabled={Object.keys(errors).length > 0}
              >
                Confirmar
              </SecondaryButton>
            </Modal.Footer>
          </Box>
        </ModalContent>
      </Modal.Root>
    </>
  )
}
