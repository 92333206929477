import { Button, Icon, SimpleGrid, VStack } from '@chakra-ui/react'
import { RiDeleteBinLine } from 'react-icons/ri'
import { Input } from '../../../../components'
import { useCities, useInstitutions } from '../../hook'
import { useRequestFilters } from '../../context/RequestFiltersContext'
import { Select } from '../../../../components/Form/Select'

export function RequestFilters() {
  const cities = useCities()

  const institutions = useInstitutions()

  const {
    guardiao,
    observerChangeInSelectStatus,
    observerChangeInSelectCity,
    observerChangeInSelectGuardioes,
    observerChangeInSelectInstituicoes,
    observerChangeInInputCPFTutor,
    observerChangeInInputCPFOrfao,
    handleButtonDelete,
  } = useRequestFilters()

  const TypeTutor = '1'
  const TypeInstitution = '2'

  const hasGuardiaoIsTypeTutor = guardiao === TypeTutor
  const hasGuardiaoIsTypeInstitution = guardiao === TypeInstitution

  return (
    <VStack my="8" as="form">
      <SimpleGrid minChildWidth="144px" gap="4" width="100%">
        <Select
          _required={false}
          label="Status"
          name="status"
          onChange={(e) => observerChangeInSelectStatus(e.target.value)}
        >
          <option value="">--Selecione--</option>
          <option value="1">DOCUMENTAÇÃO PENDENTE</option>
          <option value="2">CADASTRADA</option>
          <option value="3">EM ANÁLISE</option>
          <option value="4">EM CORREÇÃO</option>
          <option value="5">CORRIGIDA</option>
          <option value="6">DEFERIDA</option>
          <option value="7">INDEFERIDA</option>
        </Select>

        <Select
          _required={false}
          as="select"
          label="Municípios"
          name="municipios"
          onChange={(e) => observerChangeInSelectCity(e.target.value)}
        >
          <option value="">--Selecione--</option>
          {cities?.map((city) => (
            <option key={city.id} value={city.id}>
              {city.nome}
            </option>
          ))}
        </Select>

        <Select
          _required={false}
          as="select"
          label="Guardiões"
          name="guardioes"
          onChange={(e) => observerChangeInSelectGuardioes(e.target.value)}
        >
          <option value="">--Selecione--</option>
          <option value="1">Tutor</option>
          <option value="2">Instituição</option>
        </Select>

        {hasGuardiaoIsTypeInstitution && (
          <Select
            _required={false}
            as="select"
            label="Instituição"
            name="guardioes"
            onChange={(e) => observerChangeInSelectInstituicoes(e.target.value)}
          >
            <option value="">--Selecione--</option>
            {institutions?.map((institution) => (
              <option key={institution.id} value={institution.id}>
                {institution.nome}
              </option>
            ))}
          </Select>
        )}

        {hasGuardiaoIsTypeTutor && (
          <Input
            _required={false}
            variant={'flushed'}
            bgColor="platinum.100"
            label="CPF do Tutor"
            name="tutor"
            type="text"
            mask="999.999.999-99"
            placeholder="000.000.000-00"
            onChange={(e) => observerChangeInInputCPFTutor(e.target.value)}
          />
        )}

        <Input
          _required={false}
          label="CPF do orfão"
          variant={'flushed'}
          bgColor="platinum.100"
          name="orfao"
          type="text"
          mask="999.999.999-99"
          placeholder="000.000.000-00"
          onChange={(e) => observerChangeInInputCPFOrfao(e.target.value)}
        />

        <Button
          type="reset"
          variant="outline"
          title="Limpar todos os filtros"
          bgColor="platinum.400"
          color="black.200"
          _hover={{ bgColor: 'platinum.600', color: 'black.400' }}
          size="md"
          p="0"
          mt="8"
          onClick={() => handleButtonDelete()}
          leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
        >
          Limpar filtros
        </Button>
      </SimpleGrid>
    </VStack>
  )
}
