export class NIS {
  private static REGEX_FORMAT = /^(\d{3})(\d{5})(\d{2})(\d)$/
  private static REGEX_UN_FORMAT = /\D/g

  static addFormattingForNIS(nis: string): string {
    const cleanedNIS = this.unFormat(nis)

    return cleanedNIS.replace(this.REGEX_FORMAT, '$1.$2.$3-$4')
  }

  static unFormat(nis: string): string {
    return nis.replace(this.REGEX_UN_FORMAT, '')
  }
}
