import * as yup from 'yup'

export class Document {
  private static MAX_SIZE_IN_MEGABYTE = 1
  private static ONE_KILOBYTE = 1024

  static validateSize(files: FileList): boolean {
    let valid = true
    if (files && files.length > 0) {
      Array.from(files).forEach((file: File) => {
        const sizeFileInBytes = file.size
        const sizeFileInMegabyte = this.convertBytesToMegabyte(sizeFileInBytes)
        if (sizeFileInMegabyte > this.MAX_SIZE_IN_MEGABYTE) valid = false
      })
    }
    return valid
  }

  private static convertBytesToMegabyte(bytes: number): number {
    return bytes / this.ONE_KILOBYTE / this.ONE_KILOBYTE
  }
}

export const AttachmentFormValidatorSchema = yup.object().shape({
  documento: yup
    .mixed()
    .test(
      'fileSize',
      'Adicione um arquivo pdf',
      (value: string | any[]) => !!value && value.length > 0,
    )
    .test('maxSize', 'Tamanho máximo por arquivo: 1MB', (value): boolean =>
      Document.validateSize(value),
    ),
})
