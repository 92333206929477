import { Link as ChakraLink, Text, Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

type PreviousPath = {
  id: string
  name: string
  url: string
}

interface BreadcrumbProps {
  PreviousPaths?: PreviousPath[]
  currentPath: string
}

export function Breadcrumb({ PreviousPaths, currentPath }: BreadcrumbProps) {
  return (
    <Flex flexFlow={'wrap'}>
      {PreviousPaths?.map((path: PreviousPath) => (
        <Flex key={path.id}>
          <Link to={`${path.url}`}>
            <Text
              _hover={{
                color: 'blue.400',
              }}
              pb={2}
            >
              {path.name}
            </Text>
          </Link>
          <Text pb={2} px={3}>
            &gt;
          </Text>
        </Flex>
      ))}
      <Text fontWeight={'semibold'} pb={2} color={'blue.400'}>
        {currentPath}
      </Text>
    </Flex>
  )
}
