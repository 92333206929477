import { useState, useEffect } from 'react'

import { Flex, Heading, SimpleGrid, Spinner } from '@chakra-ui/react'

import { RequestFilters, RequestCard } from './components'

import { useBenefitRequests } from './hook/'

import { useRequestFilters } from './context/RequestFiltersContext'

import { Alert, Content, Pagination, Subtitles } from '../../components'

import { useAuth } from '../../data/context/AuthContext'

export function RequestsList() {
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 9

  const { statusId, cityId, instituicaoId, CPFTutor, CPFOrfao } =
    useRequestFilters()

  useEffect(() => {
    setCurrentPage(1)
  }, [statusId, cityId, CPFTutor, CPFOrfao])

  function hasValue(value: string): string | undefined {
    return value !== '' ? value : undefined
  }

  const filtros = {
    status: hasValue(statusId),
    municipio: hasValue(cityId),
    cpf_tutor: hasValue(CPFTutor),
    cpf_orfao: hasValue(CPFOrfao),
    instituicao: hasValue(instituicaoId),
  }

  const { user } = useAuth()
  const agenteId = user && user.agentecras_id

  const {
    error,
    isLoading,
    isFetching,
    data: benefits,
  } = useBenefitRequests({
    currentPage,
    perPage,
    agenteId,
    filtros,
  })

  const hasNoBenefitsRequest = Boolean(!benefits?.results.length)
  const hasBenefitsRequest = benefits?.results.length

  document.title = 'Agente CRAS - Solicitações de benefícios'

  return (
    <Content>
      <>
        <Heading size="lg" fontWeight="normal" mb="0">
          Solicitações de benefícios
          {!isLoading && isFetching && (
            <Spinner marginLeft="4" size="sm" color="blue.300" />
          )}
        </Heading>

        <RequestFilters />
        <Subtitles subtitlesIdFilter={[2, 3, 4, 5, 6, 7]} />

        {isLoading && (
          <Flex justifyContent="center" align="center">
            <Spinner color="blue.300" />
          </Flex>
        )}

        {hasNoBenefitsRequest && error && (
          <Alert
            variant="solid"
            type="error"
            title="Ocorreu um erro"
            message="Não foi possível listar as solicitações de benefício."
          />
        )}

        {!error && !isLoading && hasNoBenefitsRequest && (
          <Alert
            variant="solid"
            type="warning"
            title="Nenhuma solicitação de benefício encontrada"
            message="Não encontramos nenhuma solicitação de benefício."
          />
        )}

        {hasBenefitsRequest !== undefined && hasBenefitsRequest > 0 && (
          <SimpleGrid flex="1" gap="4" minChildWidth="320px">
            {benefits?.results.map((benefitRequest) => (
              <RequestCard key={benefitRequest.id} request={benefitRequest} />
            ))}
          </SimpleGrid>
        )}

        {hasBenefitsRequest !== undefined &&
          benefits &&
          benefits?.count > 9 && (
            <Pagination
              totalCountOfRegisters={benefits?.count}
              registersPerPage={perPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              titlePage="solicitações"
            />
          )}
      </>
    </Content>
  )
}
