import axios, { AxiosInstance, AxiosError } from 'axios'
import { getRefreshToken, setToken } from '../../store'

const REFRESH_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/api/refresh/'
    : 'https://api.rnacolhe.sethas.rn.gov.br/api/refresh/'

let isRefreshing = false
let failedQueue: any[] = []
let requestLimit = 0

const processQueue = (token = null) => {
  failedQueue.forEach((req) => {
    req.headers.Authorization = 'Bearer ' + token
  })
  requestLimit = 0
  failedQueue = []
}

export const interceptor =
  (axiosInstance: AxiosInstance) => (error: AxiosError) => {
    const _axios = axiosInstance
    let originalRequest: any = {}
    originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        failedQueue.push(originalRequest)
        requestLimit++
        if (requestLimit > 50) return Promise.reject(error)
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = getRefreshToken()
      return new Promise((resolve, reject) => {
        axios
          .post(REFRESH_URL, {
            refresh: refreshToken,
          })
          .then((data) => {
            const token = data.data.access
            setToken(token)
            _axios.defaults.headers.common.Authorization = 'Bearer ' + token
            originalRequest.headers.Authorization = 'Bearer ' + token
            processQueue(token)
            resolve(_axios(originalRequest))
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
    return Promise.reject(error)
  }
