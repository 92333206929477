import { Flex, Heading, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { Alert, Content, Pagination } from '../../components'
import { TutorCard } from '../TutoresList/components/TutorCard/index'
import { useTutores } from './hook/useTutores'

export function TutoresList() {
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 9

  const {
    error,
    isLoading,
    isFetching,
    data: tutores,
  } = useTutores({
    currentPage,
    perPage,
  })

  const hasNoTutores = Boolean(!tutores?.results.length)
  const hasTutores = tutores?.results.length

  document.title = 'Agente CRAS - Tutores'

  return (
    <Content>
      <>
        <Heading size="lg" fontWeight="normal" mb="40px">
          Tutores
          {!isLoading && isFetching && (
            <Spinner marginLeft="4" size="sm" color="blue.300" />
          )}
        </Heading>

        {isLoading && (
          <Flex justifyContent="center" align="center">
            <Spinner color="blue.300" />
          </Flex>
        )}

        {hasNoTutores && error && (
          <Alert
            variant="solid"
            type="error"
            title="Ocorreu um erro"
            message="Não foi possível listar os tutores."
          />
        )}

        {!error && !isLoading && hasNoTutores && (
          <Alert
            variant="solid"
            type="warning"
            title="Nenhum tutor encontrado"
            message="Não encontramos nenhum tutor."
          />
        )}

        {hasTutores !== undefined && hasTutores > 0 && (
          <SimpleGrid flex="1" gap="4" minChildWidth="320px">
            {tutores?.results.map((tutor) => (
              <TutorCard key={tutor.id} tutor={tutor} />
            ))}
          </SimpleGrid>
        )}

        {hasTutores !== undefined && tutores && tutores?.count > 9 && (
          <Pagination
            totalCountOfRegisters={tutores?.count}
            registersPerPage={perPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            titlePage="tutores"
          />
        )}
      </>
    </Content>
  )
}
