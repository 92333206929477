import { useQuery } from 'react-query'

import { getTutores } from '../data-fetcher/get-tutores'

export interface TutoresRequest {
  currentPage: number
  perPage: number
}

export const useTutores = ({ currentPage, perPage }: TutoresRequest) => {
  return useQuery(
    ['tutores', currentPage, perPage],
    () => getTutores({ currentPage, perPage }),
    {
      refetchOnWindowFocus: true,
      retry: true,
    },
  )
}
