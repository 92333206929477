import { ListItem, Badge, Text } from '@chakra-ui/react'
import { getBGColorByStatus } from '../../utils/get-bg-color-by-status'
import { getColorStatus } from '../../utils/get-color-status'

interface SubtitleProps {
  name: string
  info: string
}

export function Subtitle({
  name,
  info,
}: SubtitleProps) {
  return (
    <ListItem>
      <Text fontSize="sm">
        <Badge rounded="full" mr="1" bg={getBGColorByStatus(name)} color={getColorStatus(name)}>
          {name}
        </Badge>
        {info}
      </Text>
    </ListItem>
  )
}
