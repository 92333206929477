import {
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { RiMenuLine, RiLogoutBoxLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useSidebarDrawer } from '../../data/context/SidebarDrawerContext'
import { useAuth } from '../../data/context/AuthContext'
import { Logo } from './Logo'
import { Profile } from './Profile'

export function Header() {
  const { signOut } = useAuth()
  const { onOpen } = useSidebarDrawer()
  const navigateTo = useNavigate()

  function handleLogout() {
    signOut()
    navigateTo('/')
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const buttonSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  })

  return (
    <Flex
      as={'header'}
      w={'100%'}
      maxWidth={1480}
      h={'20'}
      mx={'auto'}
      px={'6'}
      align={'center'}
      justify={'space-between'}
      mb={{ base: '1rem', md: '2rem' }}
    >
      {!isWideVersion && (
        <IconButton
          aria-label="Abrir menu"
          color="platinum.50"
          icon={<Icon as={RiMenuLine} />}
          fontSize="24"
          variant="unstated"
          onClick={onOpen}
          mr="2"
        />
      )}

      <Logo />

      <Flex align="center" ml="auto">
        <Profile showProfileData={isWideVersion} />
        <Button
          variant={'outline'}
          color={'white'}
          borderColor={'white'}
          size={buttonSize}
          _hover={{ bgColor: 'white', color: 'blue.300' }}
          leftIcon={
            <Icon
              as={RiLogoutBoxLine}
              fontSize={{ base: '0.75rem', sm: '0.875rem', md: '1rem' }}
            />
          }
          onClick={handleLogout}
          title="Sair"
          ml={2}
          borderRadius={'0.25rem'}
        >
          <Text
            fontWeight={'semibold'}
            lineHeight={'1.75rem'}
            fontSize={{ base: 'xs', sm: 'sm', md: 'md', lg: 'lg' }}
          >
            Sair
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}
