import { api } from '../../../services'

export interface Institution {
  id: number
  nome: string
}

export const getInstitutions = async (
  agenteId: number,
): Promise<Institution[]> => {
  try {
    const { data } = await api.get<Institution[]>(
      `/agentescras/${agenteId}/instituicoes`,
    )
    return data
  } catch (error) {
    return []
  }
}
