import { useQuery } from 'react-query'
import { getTutor } from '../data-fetcher/get-tutor'

export interface TutorRequest {
  tutorId: number
}

export const useTutor = ({ tutorId }: TutorRequest) => {
  return useQuery(['tutor', tutorId], () => getTutor(tutorId), {
    refetchOnWindowFocus: true,
  })
}

