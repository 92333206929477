import { useEffect, useState } from 'react'
import { getInstitutions, Institution } from '../data-fetcher'
import { useAuth } from '../../../data/context/AuthContext'

export const useInstitutions = () => {
  const [institutions, setInstitutions] = useState<Institution[]>([])
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      getInstitutions(user.agentecras_id).then((data) => {
        setInstitutions(data)
      })
    }
  }, [user])

  return institutions
}
