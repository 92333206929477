import { api } from '../../../services'
import { BenefitsRequests } from '../hook/'

export interface Request {
  id: number
  cpf_orfao: string
  cpf_solicitador?: string
  status: string
  created_at: string
  municipio: string
  nome_orfao: string
  nome_solicitador: string
}

interface Response {
  count: number
  results: Request[]
}

export const getBenefitRequests = async ({
  currentPage,
  perPage,
  agenteId,
  filtros,
}: BenefitsRequests): Promise<Response> => {
  const baseUrl = `/agentescras/solicitacoes/${agenteId}/?limit=${perPage}&offset=${
    (currentPage - 1) * perPage
  }`

  let urlWithFiltros = baseUrl

  Object.entries(filtros).forEach((filtro) => {
    if (filtro[1]) {
      urlWithFiltros += `&${filtro[0]}=${filtro[1]}`
    }
  })

  const {
    data: { count, results },
  } = await api.get<Response>(urlWithFiltros)

  return { count, results }
}
