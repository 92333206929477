import { Button } from '@chakra-ui/react'

interface PaginationItemProps {
  isCurrent?: boolean
  number: number
  onPageChange: (page: number) => void
}

export function PaginationItem({
  isCurrent = false,
  number,
  onPageChange,
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Button
        size={'sm'}
        fontSize={'xs'}
        width={'4'}
        color={'platinum.50'}
        disabled
        _hover={{ bgColor: 'blue.50' }}
        _disabled={{
          bgColor: 'blue.50',
          cursor: 'default',
        }}
      >
        {number}
      </Button>
    )
  }

  return (
    <Button
      size={'sm'}
      fontSize={'xs'}
      width={'4'}
      color={'platinum.50'}
      bgColor={'blue.500'}
      _hover={{
        bgColor: 'blue.50',
      }}
      onClick={() => onPageChange(number)}
    >
      {number}
    </Button>
  )
}
