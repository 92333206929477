import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  SimpleGrid,
  Divider,
  Heading,
  Icon,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai'

import { PurportCard } from '../PurportCard'
import { usePurport } from '../../hook'
import { Request } from '../../data-fetcher'
import { Input } from '../../../../components'
import { api } from '../../../../services'

interface PurportPros {
  isOpen: boolean
  onClose: () => void
  request: Request
}

export function PurportModal({ isOpen, onClose, request }: PurportPros) {
  const pathUrl = api.defaults.baseURL?.replace('/api/', '')
  const { purport } = usePurport(request.id, isOpen)

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={purport ? isOpen : false}
        motionPreset="none"
        size={'xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Parecer da Avaliação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={'8'}>
              <SimpleGrid minChildWidth={'244px'} w={'100%'}>
                <PurportCard request={request} />
              </SimpleGrid>
              <Divider borderColor={'gray.700'} />
              <Heading size={'md'}>Parecer geral da solicitação</Heading>
              <SimpleGrid
                minChildWidth={'244px'}
                spacing={['6', '8']}
                w={'100%'}
              >
                <Input
                  name="parecer"
                  type={'text'}
                  as={'textarea'}
                  value={purport?.parecer}
                  isReadOnly
                  minH={'130px'}
                  variant={'unstyled'}
                  rounded="8"
                  size={'md'}
                  p="4"
                  boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
                  textAlign={'justify'}
                  _required={false}
                />
              </SimpleGrid>
              {purport?.anexos && (
                <>
                  <Divider my={'6'} borderColor={'gray.700'} />
                  <Heading size={'md'}>Documentos </Heading>
                  {purport.anexos.map(
                    ({ id, tipo, justificativa, arquivo, valido }) => (
                      <VStack align="stretch" w={'100%'} key={id}>
                        <Heading size={'sm'} mb={3}>
                          <Icon
                            as={!valido ? AiFillCloseCircle : AiFillCheckCircle}
                            color={!valido ? 'red.500' : 'green.500'}
                            mr={1}
                            w={4}
                            h={4}
                            verticalAlign={'middle'}
                          />
                          {tipo}
                        </Heading>
                        <ChakraLink
                          href={`${pathUrl}${arquivo}`}
                          isExternal
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent="center"
                          minWidth="fit-content"
                          rounded="md"
                          bgColor={'yellow.700'}
                          color={'platinum.50'}
                          px="4"
                          py="2"
                        >
                          <Icon as={ExternalLinkIcon} mr={2} />
                          Visualizar documento
                        </ChakraLink>
                        {justificativa && (
                          <Input
                            name={`documento-${id}`}
                            as={'textarea'}
                            type={'text'}
                            variant={'unstyled'}
                            value={justificativa}
                            isReadOnly
                            height={'150px'}
                            textAlign={'justify'}
                            size={'md'}
                            p={'3'}
                          />
                        )}
                        <Divider py={'2'} borderColor={'gray.700'} />
                      </VStack>
                    ),
                  )}
                </>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size={'md'}
              color={'platinum.50'}
              onClick={onClose}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
