import { getRefreshToken } from './get-refresh-token'
import { jwtDecode } from '../data/context/AuthContext'

export function isTokenExpired(): boolean {
  const token = getRefreshToken()

  if (!token) {
    return false
  }

  const { exp, iat } = jwtDecode(token)
  const now = new Date().getTime() / 1000
  return exp > now && iat < now
}
