import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { RiCloseLine } from 'react-icons/ri'
import {
  Input,
  Modal,
  ModalConfirm,
  SecondaryButton,
  TertiaryButton,
} from '../../../components'
import { useUpdatePessoalTutor } from '../hook/useUpdatePessoalTutor'

export function ModalDadosPessoais({ data, title, isLoading, ...props }: any) {
  const modalSize = useBreakpointValue({ base: 'xs', md: 'xl', '3xl': 'xl' })
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  const { register, handleSubmit, updatePessoal, errors, isSubmitting, reset } =
    useUpdatePessoalTutor({
      tutor: data,
      onCloseModal: props.onClose,
      onCloseConfirm: onCloseConfirm,
    })

  const handleCloseModal = () => {
    reset()
    props.onClose()
  }

  return (
    <>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isSubmitting={isSubmitting}
        title={'Confirmar atualização'}
        description={
          'Após a confirmação, os campos modificados serão salvos. Deseja realmente confirmar essa alteração?'
        }
        onConfirm={() => handleSubmit(updatePessoal)()}
      />

      <Modal.Root
        size={modalSize}
        onClose={handleCloseModal}
        isOpen={props.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <Box as={'form'}>
            <Modal.Header>
              <Flex justify="center" align="flex-start" width="100%" gap="10px">
                <Flex justify="center" align="flex-start" width="100%">
                  {title}
                </Flex>
                <IconButton
                  aria-label="Fechar modal"
                  color="blackAlpha.900"
                  icon={<Icon as={RiCloseLine} fontSize="1.5rem" />}
                  mt="0.20rem"
                  size="xs"
                  variant="unstated"
                  onClick={handleCloseModal}
                />
              </Flex>
            </Modal.Header>
            <Modal.Body>
              <Grid
                templateAreas={{
                  base: `"email" "telefone" "telAlt"`,
                  sm: `"email email" "telefone telAlt"`,
                }}
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                gap={'2rem'}
                w={'100%'}
              >
                <GridItem area={'email'}>
                  <Input
                    label="E-mail"
                    type={'email'}
                    defaultValue={data?.email}
                    {...register('email')}
                    error={errors.email}
                  />
                </GridItem>

                <GridItem area={'telefone'}>
                  <Input
                    label="Telefone"
                    type={'text'}
                    mask={'(99) 99999-9999'}
                    defaultValue={data?.telefone}
                    {...register('telefone')}
                    error={errors.telefone}
                  />
                </GridItem>

                <GridItem area={'telAlt'}>
                  <Input
                    label="Telefone/Cel alternativo"
                    type={'text'}
                    mask={'(99) 99999-9999'}
                    defaultValue={data?.telefone_alternativo}
                    {...register('telefone_alternativo')}
                    error={errors.telefone_alternativo}
                  />
                </GridItem>
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <TertiaryButton onClick={handleCloseModal}>Fechar</TertiaryButton>
              <SecondaryButton
                onClick={onOpenConfirm}
                isLoading={isLoading}
                loadingText="Confirmando"
                disabled={Object.keys(errors).length > 0}
              >
                Confirmar
              </SecondaryButton>
            </Modal.Footer>
          </Box>
        </ModalContent>
      </Modal.Root>
    </>
  )
}
