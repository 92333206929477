import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { GuardianFormPessoalData } from './GuardianFormData'
import { GuardianFormValidatorPessoalSchema } from '../schemas/GuardianFormValidatorPessoalSchema'
import { queryClient } from '../../../services'
import { updateTutor } from '../data-fetcher/update-tutor'
import { Tutor } from '../data-fetcher/get-tutor'

interface useUpdatePessoalTutorProps {
  tutor: Tutor
  onCloseModal: () => void
  onCloseConfirm: () => void
}

export function useUpdatePessoalTutor({
  tutor,
  onCloseModal,
  onCloseConfirm,
}: useUpdatePessoalTutorProps) {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<GuardianFormPessoalData>({
    resolver: yupResolver(GuardianFormValidatorPessoalSchema),
    mode: 'onChange',
  })

  const updatePessoal: SubmitHandler<GuardianFormPessoalData> = async (
    values,
  ) => {
    try {
      const response = await updateTutor(tutor.id, values)
      toast.success('Tutor atualizado com sucesso!')
      onCloseConfirm()
      onCloseModal()
      queryClient.invalidateQueries('tutor')
      return response
    } catch (error: any) {
      setError('email', {
        type: 'email',
        message: error?.response?.data?.mensagem,
      })
      onCloseConfirm()
      toast.error('Erro ao atualizar tutor: ' + error?.response?.data?.mensagem)
    }
  }

  return {
    register,
    handleSubmit,
    setError,
    updatePessoal,
    watch,
    errors,
    reset,
    isSubmitting,
  }
}
