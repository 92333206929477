import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  SimpleGrid,
  Divider,
  Heading,
  Icon,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai'

import { MaintenanceParecerCard } from '../MaintenanceParecerCard'
// import { usePurport } from '../../hook'
import { MaintenanceRequest } from '../../data-fetcher/get-maintenance-requests'
import { Input } from '../../../../components'
import { api } from '../../../../services'

interface PurportPros {
  isOpen: boolean
  onClose: () => void
  request: MaintenanceRequest
}

export function MaintenanceParecerModal({
  isOpen,
  onClose,
  request,
}: PurportPros) {
  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="none"
        size={'xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Parecer da Avaliação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={'8'}>
              <SimpleGrid minChildWidth={'244px'} w={'100%'}>
                <MaintenanceParecerCard request={request} />
              </SimpleGrid>
              <Divider borderColor={'gray.700'} />
              <Heading size={'md'}>Parecer geral da solicitação</Heading>
              <SimpleGrid
                minChildWidth={'244px'}
                spacing={['6', '8']}
                w={'100%'}
              >
                <Input
                  name="parecer"
                  type={'text'}
                  as={'textarea'}
                  value={request?.justificativa}
                  isReadOnly
                  minH={'130px'}
                  variant={'unstyled'}
                  rounded="8"
                  size={'md'}
                  p="4"
                  boxShadow={'2px 2px 4px rgba(0, 0, 0, 0.25)'}
                  textAlign={'justify'}
                  _required={false}
                />
              </SimpleGrid>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size={'md'}
              color={'platinum.50'}
              onClick={onClose}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
