import * as yup from 'yup'

export const GuardianFormValidatorPessoalSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  telefone: yup
    .string()
    .required('Telefone é obrigatório')
    .transform((value) => value.replace(/[^0-9]/g, ''))
    .min(11, 'Telefone deve ter no mínimo 9 caracteres')
    .max(11, 'Telefone deve ter no máximo 11 caracteres'),
  telefone_alternativo: yup
    .string()
    .transform((value) => value.replace(/[^0-9]/g, ''))
    .min(11, 'Telefone deve ter no mínimo 9 caracteres')
    .max(11, 'Telefone deve ter no máximo 11 caracteres')
    .optional(),
})
