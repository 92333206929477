import { useRef, useState, useContext } from 'react'
import { AttachmentsContext } from '../../../data/context/AttachmentContext'
import { RiUpload2Line } from 'react-icons/ri'
import {
  ButtonOutline,
  CardDocument,
  Form,
  InputFile,
} from '../../../components'

export interface Attachment {
  documento: string
}

type InfoCard = {
  label: string
  required: boolean
  type: number
}

interface CardProps {
  infoCard: InfoCard
}

export function Card({ infoCard }: CardProps) {
  const [previewPDF, setPreviewPDF] = useState('')

  const context = useContext(AttachmentsContext)

  const {
    handleFileAttachmentAbort,
    progressAttachment,
    setNameFile,
    setValue,
    errors,
    loading,
    progress,
    nameFile,
    setDocumento,
  } = context

  async function handleFileChange(e: any) {
    progressAttachment()

    setNameFile(e.target.files[0].name)
    setPreviewPDF(URL.createObjectURL(e.target.files[0]))
    setValue('documento', e.target.files)
    setDocumento(e.target.files[0])
  }

  return (
    <CardDocument.Root>
      <CardDocument.Label>
        <CardDocument.Name>
          {infoCard.label}
          {infoCard.required && (
            <CardDocument.RequiredField>*</CardDocument.RequiredField>
          )}
        </CardDocument.Name>
      </CardDocument.Label>
      <CardDocument.Content>
        <Form.Root<Attachment> as="form">
          <>
            {loading ? (
              <Form.CancelSubmission
                nameFile={nameFile}
                label={infoCard.label}
                progress={progress}
                handleCancelRequest={handleFileAttachmentAbort}
              />
            ) : !nameFile ? (
              <Form.Input width="100%">
                <InputFile
                  display="none"
                  error={errors.documento}
                  onChange={handleFileChange}
                />

                <ButtonOutline.Blue
                  as="div"
                  icon={RiUpload2Line}
                  minWidth="8.125rem"
                >
                  Anexar
                </ButtonOutline.Blue>
              </Form.Input>
            ) : (
              <Form.Link href={`${previewPDF}`}>{nameFile}</Form.Link>
            )}
          </>
        </Form.Root>
      </CardDocument.Content>
    </CardDocument.Root>
  )
}
