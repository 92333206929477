import { Flex } from '@chakra-ui/react'
import { Login } from './Login'

export function SignIn() {
  return (
    <Flex
      height={{ base: '100%', '2xl': '100vh' }}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Login />
    </Flex>
  )
}
