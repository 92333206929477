import { ChakraProps, Flex, Icon } from '@chakra-ui/react'
import { BsPencilSquare } from 'react-icons/bs'

interface SectionFormProps extends ChakraProps {
  children: React.ReactNode
  icon?: React.ElementType
  onOpen?: () => void
}

export function SectionForm({
  children,
  icon,
  onOpen,
  ...rest
}: SectionFormProps) {
  return (
    <Flex alignItems={'center'}>
      <Flex
        flexDirection={'column'}
        alignItems={'flex-start'}
        margin={'3rem 0 0.5rem'}
        gap={'0.0625rem'}
        w={'100%'}
        {...rest}
      >
        {children}
      </Flex>
      {icon && (
        <Icon as={icon} onClick={onOpen} boxSize={5} cursor={'pointer'} />
      )}
    </Flex>
  )
}
