import { AxiosResponse } from 'axios'
import { api, queryClient } from '../../../services'
import { toast } from 'react-toastify'

interface updateGuardianProps {
  agentecrasId: number | undefined
  orfaoId: number
  tutorId: number | null
  instituicaoId: number | null
}

export const updateGuardian = async ({
  orfaoId,
  tutorId,
  instituicaoId,
}: updateGuardianProps) => {
  const data = {
    novo_tutor: tutorId,
    nova_instituicao: instituicaoId,
  }
  try {
    const url = `agentescras/solicitar-atualizacao-responsavel/orfao/${orfaoId}/`
    const response = await api.post(url, data)
    toast.success(
      'A solicitação de alteração do responsável legal foi cadastrada com sucesso',
    )
  } catch (error) {
    toast.error('Erro ao enviar a solicitação, tente novamente mais tarde')
  }
}
