function titleByStatus(status: string) {
  const key = status.toUpperCase()

  const titles: { [key: string]: string } = {
    PENDENTE: 'A Solicitação de benefício está pendente de documentação',
    CADASTRADA: 'A Solicitação de benefício foi cadastrada',
    ANÁLISE: 'A Solicitação de benefício está em análise',
    CORREÇÃO: 'A Solicitação de benefício está em correção',
    CORRIGIDA: 'A Solicitação de benefício foi corrigida',
    DEFERIDA: 'A Solicitação de benefício foi aprovada',
    INDEFERIDA: 'A Solicitação de benefício foi negada',
  }

  return titles[key]
}

export function getTitleByStatus(status: string) {
  return titleByStatus(status)
}
